import React, { useEffect, useState } from "react";
import firebase from "../firebase/firebaseConfig";
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
  ScrollView,
  ImageBackground,
} from "react-native";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ScreenSize from "../components/ScreenSize";

export default function ChatsScreen(props) {
  const screenSize = ScreenSize();
  const [chats, setChats] = useState([]);
  const [isUser, setisUser] = useState(null);
  // const [currentuser, setcurrentUser] = useState([]);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        setisUser(data);

        // async function fetchData() {
        firebase
          .firestore()
          .collection("userChats")
          .doc(firebase.auth()?.currentUser?.uid)
          .onSnapshot((snapshot) => {
            setChats(snapshot.data());
            console.log("chats :", snapshot.data());
          });

        // }
        // fetchData();

      } else {
        setisUser(null);
      }
    });
  }, [
    // firebase.auth().currentUser?.uid
    // , chats
  ]);

  if (isUser && chats) {
    return (
      <View style={{ flex: 1 }}>
        <ImageBackground
          style={{ flex: 1 }}
          source={{
            uri: "https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png",
          }}
        >
          <ScrollView>
            {Object.entries(chats)
              ?.sort((a, b) => b[1].date - a[1].date)
              .map((chat) => (
                <TouchableOpacity
                  key={chat[0]}
                  onPress={() =>
                    props.navigation.navigate("ChatScreen", {
                      productData: chat[1].userInfo?.uid,
                      // userBPhotoURL: chat[1].userInfo?.photoURL
                      // currentUser: currentuser,
                      // productData: chat[1].userInfo.adOwner?.uid,
                      // combinedId:
                      //   currentuser?.uid > chat[1].userInfo?.uid
                      //     ? currentuser?.uid + chat[1].userInfo?.uid
                      //     : chat[1].userInfo?.uid + currentuser?.uid,
                    })
                  }
                >
                  {/* {console.log(chat[1]?.lastMessage?.text)} */}
                  <View
                    style={{
                      flexDirection: "row",
                      paddingBottom: 5,
                      padding: 10,
                      borderBottomWidth: 1,
                      borderColor: "grey",
                    }}
                  >
                    <Image
                      source={{ uri: chat[1].userInfo?.photoURL }}
                      style={{
                        width: screenSize.height / 10,
                        height: screenSize.height / 10,
                        borderRadius: 300,
                      }}
                    />

                    <View style={{ justifyContent: "center", marginLeft: 7 }}>
                      <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                        {chat[1].userInfo?.displayName}
                      </Text>

                      <Text style={{ fontSize: 18, fontWeight: "300" }}>
                        {chat[1].lastMessage}
                      </Text>
                    </View>
                    <View style={{ right: 5, position: "absolute" }}>
                      <Text style={{ color: "blue" }}>
                        {chat[1].date && chat[1].date.toDate().toDateString()}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              ))}
          </ScrollView>
        </ImageBackground>
      </View>
    );
  } else if (!isUser) {
    return <Text>You Are Not Logged In</Text>;
  } else {
    return <Text>You Don't Have Chats Yet</Text>
  }
}
