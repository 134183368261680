import React, { useState } from 'react';
import { StyleSheet, ScrollView, Text, View, Image, TouchableOpacity } from 'react-native';
import * as Yup from 'yup';

import { COLOURS } from '../components/database/Database';
// import SafeView from '../components/SafeView';
import Form from '../components/Forms/Form';
import FormField from '../components/Forms/FormField';
import FormButton from '../components/Forms/FormButton';
import IconButton from '../components/IconButton';
import FormErrorMessage from '../components/Forms/FormErrorMessage';
import { registerWithEmail } from '../firebase/firebaseConfig';
// import useStatusBar from '../hooks/useStatusBar';
import firebase from '../firebase/firebaseConfig';
// import { updateProfile } from 'firebase/auth';
import * as Animatable from 'react-native-animatable'
import { setDoc, doc } from "firebase/firestore";
import ChildImage from './ChildImage';
import ScreenSize from '../components/ScreenSize';
import Svg, { Path, Circle, Rect } from "react-native-svg"

const validationSchema = Yup.object().shape({
      name: Yup.string()
            .required()
            .label('Name'),
      email: Yup.string()
            .required('Please enter a valid email')
            .email()
            .label('Email'),
      password: Yup.string()
            .required()
            .min(6, 'Password must have at least 6 characters')
            .label('Password'),
      confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Confirm Password must match Password')
            .required('Confirm Password is required')
});

export default function RegisterScreen({ navigation }) {
      const screenSize = ScreenSize()
      const [passwordVisibility, setPasswordVisibility] = useState(true);
      const [rightIcon, setRightIcon] = useState('eye');
      const [confirmPasswordIcon, setConfirmPasswordIcon] = useState('eye');
      const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(true);
      const [registerError, setRegisterError] = useState('');
      const [image, setimage] = useState(null);


      function handlePasswordVisibility() {
            if (rightIcon === 'eye') {
                  setRightIcon('eye-off');
                  setPasswordVisibility(!passwordVisibility);
            } else if (rightIcon === 'eye-off') {
                  setRightIcon('eye');
                  setPasswordVisibility(!passwordVisibility);
            }
      }

      function handleConfirmPasswordVisibility() {
            if (confirmPasswordIcon === 'eye') {
                  setConfirmPasswordIcon('eye-off');
                  setConfirmPasswordVisibility(!confirmPasswordVisibility);
            } else if (confirmPasswordIcon === 'eye-off') {
                  setConfirmPasswordIcon('eye');
                  setConfirmPasswordVisibility(!confirmPasswordVisibility);
            }
      }


      async function handleOnSignUp(values, actions) {
            const { email, password } = values;

            try {
                  await registerWithEmail(email, password);
                  const coverRandom = []

                  if (image) {
                        const response = await fetch(image);
                        const blob = await response.blob();
                        const childPath = `Users/${firebase.auth().currentUser?.uid}/profilePic/${Math.random().toString(36)}`;
                        const ref = firebase.storage().ref().child(childPath)
                        await ref.put(blob).then(function () {
                              return ref.getDownloadURL();
                        }).then(function (url) {
                              console.log("Image url", url)
                              coverRandom.push(url)

                              firebase.firestore().collection('Users').doc(firebase.auth()?.currentUser?.uid).set({
                                    displayName: values.name,
                                    email: values.email,
                                    password: values.password,
                                    phoneNumber: values.phoneNumber,
                                    uid: firebase.auth()?.currentUser?.uid,
                                    creationTime: firebase.firestore.FieldValue.serverTimestamp(),
                                    lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
                                    // photo: coverRandom[0] != undefined ? coverRandom[0] : 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Clipart.png',
                                    photoURL: url,
                                    provider: 'email',
                                    language: 'english'
                              })
                                    // setDoc(doc(firebase.firestore(), "userChats", firebase.auth()?.currentUser?.uid), {})
                                    // setDoc(doc(firebase.firestore(), "userChats", googleUserData.user.uid), {})

                                    // updateProfile(firebase.auth().currentUser, {
                                    //       displayName: values.name,
                                    //       photoURL: "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Clipart.png"
                                    // })
                                    .then(() => navigation.navigate('HomeScreen'))
                        })
                  } else {

                        await firebase.firestore().collection('Users').doc(firebase.auth()?.currentUser?.uid).set({
                              displayName: values.name,
                              email: values.email,
                              password: values.password,
                              phoneNumber: values.phoneNumber,
                              uid: firebase.auth()?.currentUser?.uid,
                              creationTime: firebase.firestore.FieldValue.serverTimestamp(),
                              lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
                              // photo: coverRandom[0] != undefined ? coverRandom[0] : 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Clipart.png',
                              photoURL: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Clipart.png',
                              provider: 'email',
                              language: 'english'
                        })
                              // updateProfile(firebase.auth().currentUser, {
                              //       displayName: values.name,
                              //       photoURL: "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Clipart.png"
                              // })
                              .then(() => navigation.navigate('HomeScreen'))
                  }


            } catch (error) {
                  setRegisterError(error.message);
            }

            setDoc(doc(firebase.firestore(), "userChats", firebase.auth()?.currentUser?.uid), {})
      }

      const imageCallback = (callback) => {
            setimage(callback[0].uri)
      }

      return (
            <View style={{ flex: 1 }}>
                  {/* <Image
                        source={require('../assets/background.png')}
                        style={{
                              height: screenSize.height, width: screenSize.width, position: 'absolute',
                              alignSelf: 'center',
                              bottom: screenSize.isWide ? 0 : screenSize.ratio(7),
                              // backgroundColor: '#c1c1c1'
                        }}
                  /> */}

                  <View
                        style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              overflow: 'hidden',
                              // transform: [{ rotate: '180deg' }],
                              // height: screenSize.height / 3.5,
                              //     line-height: 0;
                              // backgroundColor: 'red'
                        }}>
                        <Svg
                              style={{
                                    display: 'block',
                                    width: '180%',
                                    alignSelf: 'center',
                                    height: screenSize.ratio(5),
                                    transform: [{ rotate: '180deg' }],
                                    position: 'relative',
                                    // position: 'absolute',

                              }}

                              // style={{ position: 'absolute', alignSelf: 'center' }}
                              // width='50%'
                              // height='30%'
                              preserveAspectRatio="none"
                              viewBox="0 0 1200 120"
                        // viewBox="0 0 1200 120"
                        // viewBox="0 0 800 280"
                        // xmlns="http://www.w3.org/2000/svg"
                        // data-name="Layer 1"
                        // width={screenSize.width}
                        // viewBox="0 0 20 20"
                        >

                              <Path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" fill='#63A2F3'></Path>


                              {/* <Path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" fill='#63A2F3'></Path>
                              <Path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" fill='#63A2F3' ></Path>
                              <Path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" fill='#63A2F3'></Path> */}

                              {/* <Path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" fill='#63A2F3' ></Path>
                        <Path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" fill='#63A2F3' ></Path>
                        <Path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" fill='#63A2F3' ></Path> */}



                              {/* <Path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" fill='#63A2F3' /> */}
                        </Svg>
                  </View>



                  <View
                        style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              // width: screenSize.ratio(3),
                              width: '100%',
                              alignSelf: 'center',
                              position: 'absolute',
                              // top: 0
                              // backgroundColor: '#000',
                        }}                  >
                        <Animatable.Image
                              animation={'fadeInUp'} delay={200} duration={1000} easing='ease-out-back'
                              source={require('../assets/light.png')}
                              style={{ height: screenSize.ratio(10), width: screenSize.ratio(25), marginHorizontal: '10%' }}
                        // style={{ height: 70, width: 25 }}
                        />
                        <Animatable.Image
                              animation={'fadeInUp'} delay={400} duration={1000} easing='ease-out-back'
                              source={require('../assets/light.png')}
                              style={{ height: screenSize.ratio(15), width: screenSize.ratio(38), opacity: 0.5, marginHorizontal: '10%' }}
                        // style={{ height: 55, width: 22, opacity: 0.5 }}
                        />
                  </View>



                  <View style={{
                        // padding: screenSize.isWide ? 0 : 15,
                        marginHorizontal: screenSize.isWide ? screenSize.width / 2 : 0,
                        alignSelf: 'center',
                        width: screenSize.isWide ? screenSize.ratio(3) : '100%',
                        // height: screenSize.height
                  }}>
                        {/* <View style={styles.container}> */}



                        <ScrollView>
                              <View style={{ alignItems: 'center', justifyContent: 'center', }}>
                                    {/* <TouchableOpacity style={{ position: 'absolute', top: 0, width: 30, height: 30, padding: 5, left: 0 }} onPress={() => navigation.goBack()}>
                                          <Ionicons name="ios-arrow-back" size={30} color='#52575D' />
                                    </TouchableOpacity> */}


                                    {/* <Svg
                                          // style={{ backgroundColor: 'aqua', height: 900, width: 900 }}
                                          // height="5000"
                                          // width="5000"
                                          viewBox="0 0 100 100"
                                          height="50%"
                                          width="50%"
                                          {...props}
                                    >
                                          <Circle cx="50" cy="50" r="45" stroke="blue" strokeWidth="2.5" fill="green" />
                                          <Rect x="15" y="15" width="70" height="70" stroke="red" strokeWidth="2" fill="yellow" />
                                    </Svg> */}







                                    <View
                                          style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: screenSize.ratio(32)
                                                // marginTop: 57
                                                // flex: 1,
                                                // marginTop: -40
                                                // top: 15,
                                                // backgroundColor: '#000'

                                          }}>
                                          <Animatable.Text
                                                animation={'fadeInUp'} duration={1000} easing='ease-out-back'
                                                style={{ color: '#fff', fontWeight: '900', letterSpacing: 3, fontSize: 40 }} >
                                                Sign Up
                                          </Animatable.Text>
                                    </View>

                                    <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: - 10 }} >
                                          <TouchableOpacity onPress={() => ChildImage(imageCallback)}>
                                                {/* <TouchableOpacity onPress={() => { navigation.navigate("ChildImage", { goingBackScreen: 'RegisterScreen' }) }}> */}
                                                <View style={styles.profileImage}>
                                                      <Image source={{ uri: image ? image : 'https://img.freepik.com/free-icon/user_318-159711.jpg' }}
                                                            // <Image source={{ uri: route.params?.coverImage[0].uri ? route.params?.coverImage[0].uri : 'https://img.freepik.com/free-icon/user_318-159711.jpg' }}
                                                            style={styles.imagepr}
                                                            resizeMode="cover" >
                                                      </Image>
                                                </View>
                                          </TouchableOpacity>
                                    </View >

                                    <View style={{
                                          flex: 1,
                                          marginHorizontal: 5,
                                          marginTop: 10,
                                          // justifyContent: 'center',
                                          // alignItems: 'center',
                                          // marginTop: 20,
                                    }}>
                                          <Form
                                                initialValues={{
                                                      name: '',
                                                      email: '',
                                                      phoneNumber: '',
                                                      password: '',
                                                      confirmPassword: '',
                                                }}
                                                validationSchema={validationSchema}
                                                onSubmit={values => handleOnSignUp(values)}
                                          >
                                                <FormField
                                                      name="name"
                                                      leftIcon="account"
                                                      placeholder="Name"
                                                // autoFocus={true}
                                                />

                                                <FormField
                                                      name="email"
                                                      leftIcon="email"
                                                      placeholder="Email"
                                                      autoCapitalize="none"
                                                      keyboardType="email-address"
                                                      textContentType="emailAddress"
                                                />

                                                <FormField
                                                      name="phoneNumber"
                                                      leftIcon="phone"
                                                      placeholder="Phone Number"
                                                />

                                                <FormField
                                                      name="password"
                                                      leftIcon="lock"
                                                      placeholder="Password"
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      secureTextEntry={passwordVisibility}
                                                      textContentType="password"
                                                      rightIcon={rightIcon}
                                                      handlePasswordVisibility={handlePasswordVisibility}
                                                />
                                                <FormField
                                                      name="confirmPassword"
                                                      leftIcon="lock"
                                                      placeholder="Confirm Password"
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      secureTextEntry={confirmPasswordVisibility}
                                                      textContentType="password"
                                                      rightIcon={confirmPasswordIcon}
                                                      handlePasswordVisibility={handleConfirmPasswordVisibility}
                                                />


                                                {<FormErrorMessage error={registerError} visible={true} />}

                                                <Animatable.View
                                                      style={{ marginBottom: 3, marginTop: 25, justifyContent: 'center', alignItems: 'center' }}
                                                      animation={'fadeInDown'} delay={600} duration={1000} easing='ease-out-back'
                                                >
                                                      <FormButton title={'SignUp'} />
                                                      {/* <TouchableOpacity
                                                style={{ backgroundColor: "#2f97ca", borderRadius: 20, justifyContent: 'center', alignItems: 'center', paddingVertical: 15, width: '100%' }}>
                                                <Text style={{ fontSize: 20, fontWeight: 'bold', alignItems: 'center', color: '#fff' }}>
                                                      SignUp
                                                </Text>
                                          </TouchableOpacity> */}
                                                </Animatable.View>

                                                <Animatable.View
                                                      animation={'fadeInDown'} delay={800} duration={1000} easing='ease-out-back'
                                                      style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                      <Text>Already have an account? </Text>
                                                      <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')}>
                                                            <Text style={{ color: "#2f97ca" }}>
                                                                  Login
                                                            </Text>
                                                      </TouchableOpacity>
                                                </Animatable.View>

                                          </Form>
                                          <IconButton
                                                style={styles.backButton}
                                                iconName="keyboard-backspace"
                                                color={COLOURS.backgroundDark}
                                                size={30}
                                                onPress={() => navigation.goBack() ? navigation.goBack() : navigation.navigate('HomeScreen')}
                                          />
                                    </View>
                                    {/* </SafeView> */}
                              </View>
                        </ScrollView>
                  </View >
            </View >

      );
}

const styles = StyleSheet.create({
      // container: {
      //       backgroundColor: '#fff',
      //       alignSelf: 'center',
      //       alignItems: 'center',
      //       justifyContent: 'center',
      //       alignContent: 'center',
      //       flex: 1
      //       // height: '100%',
      //       // width: '100%',
      // },
      backButton: {
            justifyContent: 'center',
            alignItems: 'center',
            marginVertical: 10
      },
      imagepr: {
            flex: 1,
            height: undefined,
            width: undefined
      },
      profileImage: {
            width: 120,
            height: 120,
            borderRadius: 100,
            overflow: "hidden",
            marginTop: 20
      },
});



// import { View, Text, Image, SafeAreaView, TextInput, TouchableOpacity, Pressable } from 'react-native'
// import React from 'react'
// import { StatusBar } from 'expo-status-bar'
// import { useNavigation } from '@react-navigation/native'
// import Animated, { FadeIn, FadeInDown, FadeInUp } from 'react-native-reanimated';
// import * as Animatable from 'react-native-animatable'

// export default function RegiterScreen() {
//       const navigation = useNavigation();
//       return (
//             <View
//                   style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
//             >
//                   <Image
//                         source={require('../assets/background.png')}
//                         style={{ height: '100%', width: '100%', position: 'absolute' }}
//                   />

//                   {/* lights */}
//                   <View
//                         style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%', position: 'absolute' }}                  >
//                         <Animatable.Image
//                               animation={'fadeInUp'} delay={200} duration={1000} easing='ease-out-back'
//                               source={require('../assets/light.png')}
//                               style={{ height: 200, width: 90 }}
//                         />
//                         <Animatable.Image
//                               animation={'fadeInUp'} delay={400} duration={1000} easing='ease-out-back'
//                               source={require('../assets/light.png')}
//                               style={{ height: 120, width: 65, opacity: 75 }}
//                         />
//                   </View>

//                   {/* title and form */}
//                   <View
//                         style={{
//                               height: "100%", width: '100%', flex: 1,
//                               justifyContent: 'space-around',
//                               paddingBottom: 48
//                         }}
//                   >

//                         {/* title */}
//                         <View
//                               style={{
//                                     flex: 1,
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//                                     top: 75
//                               }}
//                         >
//                               <Animatable.Text
//                                     animation={'fadeInUp'} duration={1000} easing='ease-out-back'
//                                     style={{ color: '#fff', fontWeight: 'bold', letterSpacing: 3, fontSize: 40 }}
//                               >
//                                     Sign Up
//                               </Animatable.Text>
//                         </View>

//                         {/* form */}
//                         <View
//                               style={{ flex: 1, alignItems: 'center', marginHorizontal: 5, marginTop: 20, }}
//                         >
//                               <Animatable.View
//                                     animation={'fadeInDown'} duration={1000} easing='ease-out-back'
//                                     style={{ backgroundColor: '#e8eaed', padding: 5, borderRadius: 10, width: '95%', marginVertical: 12, paddingVertical: 10 }}
//                               >
//                                     <TextInput
//                                           placeholder="Username"
//                                           placeholderTextColor={'gray'}
//                                           style={{ left: 10, textDecorationStyle: 'double' }}
//                                     />
//                               </Animatable.View>
//                               <Animatable.View
//                                     animation={'fadeInDown'} delay={200} duration={1000} easing='ease-out-back'
//                                     style={{ backgroundColor: '#e8eaed', padding: 5, borderRadius: 10, width: '95%', marginVertical: 12, paddingVertical: 10 }}
//                               >
//                                     <TextInput
//                                           placeholder="Email"
//                                           placeholderTextColor={'gray'}
//                                     />
//                               </Animatable.View>
//                               <Animatable.View
//                                     animation={'fadeInDown'} delay={400} duration={1000} easing='ease-out-back'
//                                     style={{ backgroundColor: '#e8eaed', padding: 5, borderRadius: 10, width: '95%', marginBottom: 3, marginVertical: 12, paddingVertical: 10 }}
//                               >
//                                     <TextInput
//                                           placeholder="Password"
//                                           placeholderTextColor={'gray'}
//                                           secureTextEntry
//                                     />
//                               </Animatable.View>

//                               <Animatable.View
//                                     style={{
//                                           padding: 5, width: '95%', marginBottom: 3, marginVertical: 12, justifyContent: 'center', alignItems: 'center'
//                                     }}


//                                     animation={'fadeInDown'} delay={600} duration={1000} easing='ease-out-back'
//                               >
//                                     <TouchableOpacity
//                                           style={{ backgroundColor: "#2f97ca", borderRadius: 20, justifyContent: 'center', alignItems: 'center', paddingVertical: 10, width: '100%' }}
//                                     >
//                                           <Text
//                                                 style={{ fontSize: 20, fontWeight: 'bold', alignItems: 'center', color: '#fff' }}
//                                           >SignUp
//                                           </Text>
//                                     </TouchableOpacity>
//                               </Animatable.View>

//                               <Animatable.View
//                                     animation={'fadeInDown'} delay={800} duration={1000} easing='ease-out-back'
//                                     style={{ flexDirection: 'row', justifyContent: 'center' }}
//                               >

//                                     <Text>Already have an account? </Text>
//                                     <TouchableOpacity onPress={() => navigation.push('Login')}>
//                                           <Text
//                                                 style={{ color: "#2f97ca" }}
//                                           >Login</Text>
//                                     </TouchableOpacity>

//                               </Animatable.View>
//                         </View>
//                   </View>
//             </View>
//       )
// }