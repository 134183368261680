import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  StyleSheet,
  View,
  TextInput,
  Text,
  ActivityIndicator,
  ScrollView,
  Picker,
  Image,
  TouchableOpacity,
  Pressable,
} from "react-native";
import firebase from "../firebase/firebaseConfig";
import ScreenSize from "../components/ScreenSize";
import LocationPicker from "../components/LocationPicker";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CityDropDown from "./CityDropDown";
import MapPreview from "../components/MapPreview";
import { FormControl, Input, Modal, Button, Switch } from "native-base";
import ChildImage from "./ChildImage";
import { Carousel } from 'antd';
import * as Animatable from 'react-native-animatable'
import { Spin } from 'antd';
import openNotificationWithIcon from '../components/openNotificationWithIcon'


export default function AddScreen({ navigation, route }) {
  const styles = useStyles();
  const screenSize = ScreenSize();
  const [modalVisible, setModalVisible] = useState(false)
  const [user, setUser] = useState(null);
  const [ShortDescription, setShortDescription] = useState("");
  const [LongDescription, setLongDescription] = useState("");
  const [Price, setPrice] = useState("");
  const [picker, setPicker] = useState("gamingLaptop");
  const [conditionPicker, setconditionPicker] = useState("new");
  const [phoneNumber, setphoneNumber] = useState(null);
  const [contactMethods, setContactMethods] = useState({ phoneNumber: true, chat: true })
  const [image, setImage] = useState([])

  const [Location, setLocation] = useState(null);
  const [address, setaddress] = useState(null);
  const [showLocationPicker, setShowLocationPicker] = useState(true)
  const [showCityDropDown, setShowCityDropDown] = useState(true)

  const [loadingOnlineData, setLoadingOnlineData] = useState(false)
  const [loadingLocalImages, setloadingLocalImages] = useState(false)
  const [loadingAddingData, setloadingAddingData] = useState(false)

  // const [coverImage, setcoverImage] = useState([])
  // use for loop when adding blob to firestore to add images one after another so that the index is the same

  const ref = useRef(null);
  const userDocRef = firebase
    .firestore()
    .collection("Users")
    .doc(firebase.auth().currentUser?.uid)
    .collection("Ads")
    .doc();

  useEffect(() => {
    setLoadingOnlineData(true)
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        firebase
          .firestore()
          .collection("Users")
          .doc(firebase.auth().currentUser?.uid)
          .get()
          .then((snapshot) => {
            setUser(snapshot.data());
            setContactMethods(snapshot.data().contactMethods)
            setphoneNumber(snapshot.data().phoneNumber)
            if (snapshot.data().lastAdLocation?.coordinates != undefined) {
              setaddress(snapshot.data().lastAdLocation?.address)
              setLocation(snapshot.data().lastAdLocation?.coordinates)
              setShowCityDropDown(false)
              setShowLocationPicker(false)
            }
          });
        setLoadingOnlineData(false)

      } else {
        setUser(null);
        setLoadingOnlineData(false)

      }
    });
  }, [firebase.auth().currentUser?.uid]);


  function addToFirebase() {
    if (loadingLocalImages || loadingOnlineData || loadingAddingData) {
      openNotificationWithIcon('error', 'Please wait.', 'Loading data')
    } else if (image.length == 0) {
      openNotificationWithIcon('error', 'No Image', "You can't Publish your ad without an image")
    } else if (!address) {
      openNotificationWithIcon('error', "Location can't be empty", "Choosing a location is a must")
    } else if (ShortDescription.length < 4) {
      openNotificationWithIcon('error', "Title can't be empty", "You have to add title to your ad")
    } else if (contactMethods === undefined) {
      setContactMethods({ phoneNumber: true, chat: true })
      setModalVisible(true)
    } else {
      uploadToStorage(image)
    }
  }

  const uploadToStorage = async (images, coverUri) => {
    setloadingAddingData(true)

    const random = [];
    for (let item of images) {
      const response = await fetch(item.uri);
      const blob = await response.blob();
      // const childPath = `Ads/${firebase.auth().currentUser?.uid}/${userDocRef.id}/${image.indexOf(item)}`;
      const childPath = `Ads/${firebase.auth().currentUser?.uid}/${userDocRef.id}/${Math.random().toString(36)}`;
      const ref = firebase.storage().ref().child(childPath);
      // console.log(blob);
      await ref
        .put(blob)
        .then(function () {
          return ref.getDownloadURL();
        })
        .then(function (url) {
          delete item.uri
          random.push({ ...item, downloadURL: url, fileSize: blob.size });
        });
    }



    await userDocRef
      .set({
        ShortDescription: ShortDescription,
        LongDescription: LongDescription,
        Price: Price,
        Location: Location,
        Address: address,
        isAvailable: true,
        condition: conditionPicker,
        productImageList: random,
        creation: firebase.firestore.FieldValue.serverTimestamp(),
        ProductType: picker,
        adOwner: {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
          phoneNumber: phoneNumber ? phoneNumber : null
        },
        id: userDocRef.id,
      })
      .then(() => {
        return firebase
          .firestore()
          .collection("Ads")
          .doc(userDocRef.id)
          .set({
            ShortDescription: ShortDescription,
            LongDescription: LongDescription,
            Price: Price,
            Location: Location,
            Address: address,
            isAvailable: true,
            condition: conditionPicker,
            productImageList: random,
            creation: firebase.firestore.FieldValue.serverTimestamp(),
            ProductType: picker,
            adOwner: {
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              phoneNumber: phoneNumber ? phoneNumber : null
            },
            id: userDocRef.id,
          });
      })
      .then(() => {
        if (phoneNumber) {
          return firebase.firestore().collection('Users').doc(user.uid).update({ lastAdLocation: { address: address, coordinates: Location }, contactMethods: contactMethods, phoneNumber: phoneNumber })
        } else {
          return firebase.firestore().collection('Users').doc(user.uid).update({ lastAdLocation: { address: address, coordinates: Location }, contactMethods: contactMethods })
        }
      })
      .then(navigation.navigate("HomeScreen"))

    openNotificationWithIcon('success', 'Added Succsessfully', 'Your Ad Is Published')
    setloadingAddingData(false)
  };


  const pickOnMapHandler = () => {
    window.open("https://maps.google.com?q=" + Location.lat + "," + Location.lng);
  };

  const locationPickedHandler = useCallback((location) => {
    setLocation(location);
    setShowCityDropDown(false)
    setShowLocationPicker(false)
    console.log(location)
  }, []);

  const addressPickedHandler = useCallback((address) => {
    setaddress(address);
    setShowCityDropDown(false)
    setShowLocationPicker(false)
    console.log(address)
  }, []);

  const dropdownCoordinatesPickedHandler = useCallback((dropdownCoordinates) => {
    setLocation(dropdownCoordinates);
    setShowLocationPicker(false)
    setShowCityDropDown(true)
    console.log(dropdownCoordinates)
  }, []);

  const dropdownAddressPickedHandler = useCallback((dropdownAddress) => {
    setaddress(dropdownAddress);
    setShowLocationPicker(false)
    setShowCityDropDown(true)
    console.log(dropdownAddress)
  }, []);


  const imageCallback = (callback) => {
    if (callback != 'canceled') {
      setloadingLocalImages(false)
      setImage(callback)
    } else {
      setloadingLocalImages(false)
    }
  }


  const deleteItemById = id => {
    const filteredData = image.filter(item => item.uri !== id).map((image, index) => {
      return { ...image, orderNumber: index + 1 }
    })
    setImage(filteredData);
  }

  const changeImageOrder = item => {
    const arr = [...image];
    const fromIndex = arr.indexOf(item)
    const toIndex = 0;
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
    ref.current?.goTo(0, false)
    setImage(arr)
  }

  // const AnimatedPressable = Animatable.createAnimatableComponent(Pressable)

  const slidesToShow = image?.length >= 3 ? 3 : image?.length

  const carouselWidth =
    image?.length >= 3 ? screenSize.width / 6.1 :
      image?.length == 2 ? '80%' :
        image?.length == 1 ? '50%' :
          '100%'


  if (user === null) {
    return (
      <Text>
        You Have To Sign In First
      </Text>
    )
  }
  if (user != null) {
    return (
      <View style={{
        paddingHorizontal: screenSize.isWide ? screenSize.width / 4 : 0,
        flex: 1,
        // backgroundColor: 'red',
        // overflow: 'hidden',
        // height: '100%'
      }}>
        {/* {contextHolder} */}

        {loadingAddingData ?
          <Spin
            style={{
              // alignSelf: 'center',
              // top: 0,
              // margin: 0,
              // alignSelf: 'stretch',
              // position: 'absolute',
              // flex: 1,
              width: '100%',
              height: '100%'
            }}
            size="large"
            spinning={loadingAddingData}
            fullscreen />
          : null}


        {/* <Button onPress={() => setloadingAddingData(true)}>
          open spin
        </Button> */}


        <ScrollView style={{
          // overflow: 'visible',
          // flex: 1
        }} showsVerticalScrollIndicator={false}>

          {loadingOnlineData ? (
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Loading Online Data...
              </Text>
              <ActivityIndicator size={"large"} />
            </View>
          ) : null}

          {loadingLocalImages ?
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Loading Local Images...
              </Text>
              <ActivityIndicator size={"large"} />
            </View>
            : null}

          {image.length == 0 ?
            <View style={{
              justifyContent: 'center', alignItems: 'center', marginTop: 8,
              marginBottom: -15
              // backgroundColor: 'blue'
            }}>
              <TouchableOpacity
                style={{
                  justifyContent: 'center', alignSelf: 'center', alignItems: 'center',
                  // backgroundColor: 'red'
                  // backgroundColor: '#eaeaea',
                  // borderRadius: 50,
                  // height: 90, width: 90,
                }}
                onPress={() => {
                  setloadingLocalImages(true)
                  ChildImage(imageCallback)
                }}>
                {/* <MaterialCommunityIcons name="camera-plus-outline" size={70} color="black" /> */}
                <Image style={{
                  height: screenSize.ratio(8.5), width: screenSize.ratio(8.5),
                  // borderRadius: 50
                }} source={require('../assets/uploadImage2.png')} />
              </TouchableOpacity>
              {/* <Text style={{ fontSize: 14, fontWeight: '800', marginTop: 4 }}>Add Images To Your Product</Text> */}
            </View>
            : null}

          {/* ////////////////////////////////////////////////////// CAROUSEL ///////////////////////////////////////////////////////////////////////////// */}
          {image?.length != 0 ?
            <View
              style={{
                alignSelf: screenSize.isWide ? 'center' : undefined,
                width: '100%',
                height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                justifyContent: screenSize.isWide ? 'center' : undefined,
              }}>

              <Carousel
                infinite={false}
                ref={ref}
                slidesToShow={screenSize.isWide ? slidesToShow : 1}
                style={{
                  height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                  alignSelf: 'center',
                }}
                arrows={true}
                dots={true}
                waitForAnimate={true}
                draggable={true}
                swipeToSlide
              // slidesPerRow={4}
              >

                {image?.map((item, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                      }}
                    >
                      <View>
                        <TouchableOpacity
                          onPress={() => { changeImageOrder(item, index) }}
                          style={{
                            height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                            width: screenSize.isWide ? carouselWidth : '100%',
                            alignSelf: 'center',
                          }}>

                          <Image
                            source={item.downloadURL ? item.downloadURL : item.uri}
                            style={{
                              borderRadius: 3,
                              alignSelf: 'center',
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottomRightRadius: 20,
                              borderBottomLeftRadius: 20,
                              height: '100%',
                              width: '100%',
                              aspectRatio: 1,
                              resizeMode: 'cover',
                            }} />

                          {index === 0 ?
                            <View
                              style={{
                                position: 'absolute',
                                width: '30%',
                                height: '22%',
                                backgroundColor: 'green',
                                top: 3,
                                left: 3,
                                borderTopLeftRadius: 17,
                                borderBottomRightRadius: 17,
                                opacity: 0.8,
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}>
                              <Text
                                style={{
                                  fontSize: '150%',
                                  color: '#fff',
                                  fontWeight: '900',
                                }}>
                                Cover
                              </Text>
                            </View>
                            : null}


                          <TouchableOpacity
                            style={{
                              position: 'absolute', top: 6,
                              right: 6,
                              opacity: 0.8
                            }}
                            onPress={() => { deleteItemById(item.uri) }}>
                            <MaterialCommunityIcons name="delete-circle-outline" size={24} color="red" />
                          </TouchableOpacity>
                        </TouchableOpacity>
                      </View>
                    </View>)
                })}
              </Carousel>
            </View>
            : null}
          {/* ////////////////////////////////////////////////////// CAROUSEL ///////////////////////////////////////////////////////////////////////////// */}


          <View style={{
            paddingHorizontal: 12,
            marginTop: 19,
          }}>
            <TextInput
              style={styles.textInput}
              maxLength={85}
              onChangeText={(ShortDescription) => setShortDescription(ShortDescription)}
              placeholder="Title"
            />
            <TextInput
              style={styles.textInput}
              onChangeText={(Price) => setPrice(Price)}
              placeholder="Price"
            />


            {/* ////////////////////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////////////////////// */}
            <View style={{ marginTop: 20, }}>
              {address ? <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#eaeaea",
                  borderRadius: 3,
                  marginBottom: 3,
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "600", textAlign: 'center', color: '#4f4f4f' }}>
                  {address}
                </Text>
              </View> : null}

              <View style={{
                flexDirection: 'row',
                justifyContent: showCityDropDown ? 'space-between' : 'space-around',
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
                width: '100%',
                // backgroundColor: 'green'
              }}>

                {Location ? (
                  <View style={{
                    borderRadius: 5,
                    overflow: 'hidden',
                    borderColor: "#ccc",
                    borderWidth: 1,
                    height: showCityDropDown ? '100%' : screenSize.ratio(10),
                    width: showCityDropDown ? '59.5%' : '100%',
                  }}>
                    <MapPreview
                      style={{ width: '100%', height: '100%' }}
                      location={Location}
                      onPress={pickOnMapHandler}
                    ></MapPreview>
                  </View>
                ) : null}

                {showLocationPicker || address == null ? (
                  // <View >
                  <View style={{ width: '40%', }}>
                    <LocationPicker
                      navigation={navigation}
                      onLocationPicked={locationPickedHandler}
                      onAddressPicked={addressPickedHandler}
                    // lastAdLocation={user?.lastAdLocation}
                    />
                  </View>
                ) : null}

                {showLocationPicker && showCityDropDown || address == null ? (
                  <View>
                    {/* <View style={{ width: '10%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}> */}
                    <Text style={{ color: '#133337', fontSize: 18, fontWeight: '700' }}>OR</Text>
                  </View>
                ) : null}

                {showCityDropDown || address == null ? (
                  // <View>
                  <View style={{ width: '40%' }}>
                    <CityDropDown
                      onLocationPicked={dropdownCoordinatesPickedHandler}
                      onAddressPicked={dropdownAddressPickedHandler}
                    // lastAdLocation={user?.lastAdLocation}
                    />
                  </View>
                ) : null}
              </View>


              {!showLocationPicker && !showCityDropDown && Location ? (
                // {!showLocationPicker && !showCityDropDown && Location ? (
                <TouchableOpacity
                  style={{ alignItems: "center", justifyContent: "center", position: 'absolute', bottom: -12, alignSelf: 'center' }}
                  onPress={() => {
                    setLocation(null);
                    setaddress(null);
                    setShowCityDropDown(true)
                    setShowLocationPicker(true)
                  }}
                >
                  <MaterialCommunityIcons
                    name="close-circle"
                    size={24}
                    color="#52575D"
                  />
                </TouchableOpacity>
              ) : null}

            </View>
            {/* ////////////////////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////////////////////// */}

            <TextInput
              multiline
              maxLength={2500}
              placeholder="Description"
              style={styles.textInputDescription}
              onChangeText={(LongDescription) =>
                setLongDescription(LongDescription)
              }
            />

            {/* /////////////////////////////////////////////  CPU ////////////////////////////////////////////////////////////////////////////// */}
            {/* <TextInput
              style={styles.textInput}
              onChangeText={(search) => fetchUsers(search)}
              placeholder='Cpu'
            />
            <FlatList
              numColumns={1}
              horizontal={false}
              data={cpu}
              renderItem={({ item }) => (
                <TouchableOpacity
                  // onPress={() => props.navigation.navigate('ProductInfo', {
                  //       // placeTitle: item.name,
                  //       data: item

                  // })}
                  onPress={() => setcpuFinal(item.model)
                    // props.navigation.navigate("KidsProfile", { uid: item.id })
                  }
                >
                  <Text style={styles.text}>{item.model}</Text>
                </TouchableOpacity>)} /> */}
            {/* ///////////////////////////////////////////////////////////   CPU  ///////////////////////////////////////////////////////////// */}

            <View style={{
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 6,
              width: '100%'
            }}>
              <Picker
                selectedValue={picker}
                onValueChange={(picker) => setPicker(picker)}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  marginVertical: 4,
                  paddingVertical: 3,
                  flex: 1,
                  padding: 5,
                  borderRadius: 5
                }}
                mode="dropdown"
                itemStyle={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  // padding: 10,
                }}
              >
                {/* <Picker.Item label="اختار" value="اختار" /> */}
                <Picker.Item label="Gaming Laptop" value="gamingLaptop" />
                <Picker.Item label="Business Laptop" value="businessLaptop" />
                {/* <Picker.Item label="scarf" value="scarf" /> */}
                {/* <Picker.Item label="bonnet" value="bonnet" /> */}
                <Picker.Item label="Workstation" value="workstation" />
                <Picker.Item label="Other" value="other" />
                {/* <Picker.Item label="غير ذلك" value="غير ذلك" /> */}
              </Picker>

              <Picker
                selectedValue={conditionPicker}
                onValueChange={(conditionPicker) =>
                  setconditionPicker(conditionPicker)
                }
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  paddingVertical: 3,
                  marginVertical: 4,
                  flex: 1,
                  padding: 5,
                  borderRadius: 5,
                  // marginBottom: 2000
                }}
                mode="dropdown"
                itemStyle={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  // padding: 10,
                }}
              >
                <Picker.Item label="New" value="new" />
                <Picker.Item label="Used" value="used" />
              </Picker>
            </View>
          </View >

          {screenSize.isWide && image?.length > (screenSize.width / 2) / (screenSize.width / 6) ?
            // {product.productImageList?.length != 0 && screenSize.isWide ?
            <View style={styles.ButtonBox}>
              <TouchableOpacity
                onPress={() => { ref.current?.prev(); }} >
                <AntDesign
                  name="leftcircle"
                  size={screenSize.ratio(30)}
                  color='#9b9b9b'
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => { ref.current?.next(); }}>
                <AntDesign
                  name="rightcircle"
                  size={screenSize.ratio(30)}
                  color='#9b9b9b'
                />
              </TouchableOpacity>
            </View>
            : null}

          <View
            style={{
              // position: 'absolute',
              alignSelf: 'center',
              width: screenSize.isWide ? screenSize.width / 2.06 : '95%',
              height: '6%',
              borderRadius: 5,
              bottom: 5,
              marginTop: 20
              // overflow: 'hidden',
            }}
          >

            <Button
              disabled={loadingAddingData || loadingLocalImages || loadingOnlineData}
              style={{ opacity: loadingAddingData || loadingLocalImages || loadingOnlineData ? 0.3 : 1 }}
              onPress={() => {
                addToFirebase()
                // setloadingAddingData(true)
                // setLoadingModalVisible(true)
              }} >
              Add
            </Button>
          </View>

        </ScrollView >



        <Modal isOpen={modalVisible} onClose={() => {
          setModalVisible(false)
          addToFirebase()
        }}>
          <Modal.Content maxWidth="400px">
            <Modal.CloseButton />
            <Modal.Header>Choose Contact Methods</Modal.Header>
            <Modal.Body>
              <FormControl>
                <FormControl.Label>We Recommend That You Add A Phone Number As It Increases The Chance To Be Contacted</FormControl.Label>

                {user.phoneNumber == undefined ?
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                    <Text style={{ fontSize: 15 }}>Add Your Phone Number</Text>
                    <Input onChangeText={(text) => { setphoneNumber(text) }} />
                  </View>
                  : null}

                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 25,
                  marginBottom: 5
                }}>
                  <Text style={{ fontSize: 15 }}>Phone Number</Text>
                  <Switch
                    isDisabled={contactMethods?.chat == false}
                    onToggle={() => {
                      setContactMethods(prevState => ({ ...prevState, phoneNumber: !prevState.phoneNumber }));
                    }}
                    // name="Phone Number"
                    value={contactMethods?.phoneNumber}
                    offTrackColor="orange.100" onTrackColor="orange.200" onThumbColor="orange.500" offThumbColor="orange.50" />
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 15 }}>Chat</Text>
                  <Switch
                    isDisabled={contactMethods?.phoneNumber == false || !phoneNumber || phoneNumber?.length < 11}
                    onToggle={() => {
                      setContactMethods(prevstate => ({ ...prevstate, chat: !prevstate.chat }))
                    }}
                    // name="Chat"
                    value={contactMethods?.chat}
                    offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                </View>

              </FormControl>
            </Modal.Body>
            <Modal.Footer>

              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                  setModalVisible(false);
                }}>
                  Cancel
                </Button>
                <Button onPress={() => {
                  setModalVisible(false);
                }}>
                  Save
                </Button>
              </Button.Group>

            </Modal.Footer>
          </Modal.Content>
        </Modal>

      </View >
    );
  }
}

function useStyles() {
  return StyleSheet.create({
    textInput: {
      borderBottomColor: "#ccc",
      borderBottomWidth: 1,
      paddingVertical: 4,
      paddingHorizontal: 2,
      marginBottom: 10,
    },
    textInputDescription: {
      borderColor: "#ccc",
      // marginBottom: 30,
      marginTop: 25,
      // marginTop: 27,
      // paddingVertical: 60,
      paddingHorizontal: 2,
      borderWidth: 1,
      height: 100,
      borderRadius: 5

      // alignItems: "flex-start",
      // justifyContent: 'space-around',
      // borderBottomColor: '#ccc',
      // borderBottomWidth: 1,
    },
    textInputDelete: {
      borderBottomColor: "#ccc",
      borderBottomWidth: 5,
      marginBottom: 30,
      paddingVertical: 20,
      paddingHorizontal: 2,
      borderTopColor: "#0000ff",
      // borderRadius:50,
      borderEndWidth: 20,
    },
    locationPicker: {
      marginBottom: 15,
    },
    mapPreview: {
      marginBottom: 10,
      width: "100%",
      height: 150,
      borderColor: "#ccc",
      borderWidth: 1,
      borderRadius: 10
    },
    actions: {
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
    },
    imagePicker: {
      alignItems: "center",
      marginBottom: 15,
    },
    imagePreview: {
      width: "100%",
      height: 200,
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      borderColor: "#ccc",
      borderWidth: 1,
    },
    image: {
      width: "100%",
      height: "100%",
    },
    loadingIndicator: {
      zIndex: 5,
      width: "100%",
      height: "100%",
    },
    ButtonBox: {
      position: "absolute",
      flexDirection: "row",
      alignItems: "center",
      top: ScreenSize().ratio(12),
      justifyContent: "space-between",
      alignContent: "center",
      alignSelf: "center",
      width: "107.5%",
      // backgroundColor: 'red'
    },
    sheet: {
      // backgroundColor: "rgba(0, 0, 0, 0.3)",
      backgroundColor: "white",
      padding: 16,
      height: '30%',
      // height: ScreenSize().height / 1.5,
      width: "70%",
      position: "absolute",
      // bottom: -ScreenSize().height / 6,
      borderRadius: 20,
      zIndex: 1,
      alignSelf: 'center',
      bottom: ScreenSize().height * 0.35
      // marginHorizontal: 1000,
      // bottom: -20 * 7,
      // borderTopRightRadius: 20,
      // borderTopLeftRadius: 20,
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    },
  })
}



