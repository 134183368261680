import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  LogBox,
  FlatList,
  TouchableOpacity,
  Image,
  Dimensions,
  Button,
} from "react-native";
import firebase from "../firebase/firebaseConfig";
import {
  getAuth,
  // GoogleAuthProvider,
  // signInWithCredential,
  onAuthStateChanged,
} from "firebase/auth";

export default function SellingScreen(props) {
  const [loading, setLoading] = useState(false);
  const [meals, setMeals] = useState([]);
  const [MakhdomGrade, setMakhdomGrade] = useState("");
  const [KhademGrade, setKhademGrade] = useState("");
  const [isUser, setisUser] = useState();

  useEffect(() => {
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        let isMounted = true;
        let mealsData = [];
        setLoading(true)
        setisUser(data);
        firebase
          .firestore()
          .collection("Users")
          .doc(firebase.auth().currentUser.uid)
          .collection("Ads")
          .onSnapshot((snapshot) => {
            if (isMounted) {
              snapshot.docChanges().forEach((data) => {
                let product = data.doc.data();
                product.id = data.doc.id;
                mealsData.push(product);
              });
              setMeals(mealsData);
              setLoading(false);
            }
          });
      } else {
        setisUser(null);
      }
    });
  }, [
    // isUser, meals
  ]);


  const ProductCard = ({ data, index }) => {
    return (
      <TouchableOpacity
        key={index}
        // onPress={() => navigation.navigate('ProductInfo', { data: data })}
        onPress={() =>
          props.navigation.navigate("EditScreen", {
            // placeTitle: item.name,
            productId: data.id,
          })
        }
        style={{
          width: "48%",
          marginVertical: 14,
        }}
      >
        <View
          style={{
            // flex: 1,
            width: 300,
            height: 100,
            borderRadius: 10,
            backgroundColor: "black",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          {data.offPercentage > 0 ? (
            <View
              style={{
                position: "absolute",
                width: "20%",
                height: "24%",
                backgroundColor: "black",
                top: 0,
                left: 0,
                borderTopLeftRadius: 10,
                borderBottomRightRadius: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "black",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                {data.offPercentage}%
              </Text>
            </View>
          ) : null}
          <Image
            source={data.photo}
            style={{
              width: "80%",
              height: "80%",
              resizeMode: "contain",
            }}
          />
        </View>
        <Text
          style={{
            fontSize: 12,
            color: "black",
            fontWeight: "600",
            marginBottom: 2,
          }}
        >
          {data.ShortDescription}
        </Text>

        <Text>&#65284; {data.Price}</Text>
      </TouchableOpacity>
    );
  };

  if (isUser != null) {
    return (
      <View style={styles._container}>
        <ScrollView>
          {meals.length === 0 ? (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                fontSize: 20,
                fontWeight: "800",
              }}
            >
              <Text>Your Ads Will Appear Here</Text>
              <Text>You Are Currently Not Selling Any Thing</Text>
            </View>
          ) : null}

          {meals.map((data, index) => {
            return (
              <ProductCard data={data} key={index} />
            );
          })}
          <View style={{}}>
            <Button
              style={{
                alignItems: "center",
                justifyContent: "center",
                fontSize: 30,
                fontWeight: "bold",
              }}
              onPress={() => {
                props.navigation.navigate("AddScreen");
              }}
              title="Add Your Product Now !"
            ></Button>
          </View>
        </ScrollView>
      </View>
    );
  } else if (isUser === null) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text>You Have To Log In First To Post Your Ad </Text>
      </View>
    );
  } else if (loading) {
    <View>
      <Text style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
        Uploading...
      </Text>
      <ActivityIndicator size={"large"} />
    </View>
  }
}

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
  _container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: 60
  },
  _loader: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  loader: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  placeItem: {
    width: windowWidth,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    paddingVertical: 10,
    // padding:3,
    paddingHorizontal: 3,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  imageCard: {
    width: windowWidth * 0.3,
    // 50,
    height: windowWidth * 0.3,
    // 50,
    borderRadius: 200,
    backgroundColor: "#a9a9a9",
    borderColor: "black",
    borderWidth: 1,
  },
  infoContainer: {
    marginLeft: 20,
    width:
      // 200,
      windowWidth - windowWidth * 0.4,
    // height: 200,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  title: {
    color: "black",
    fontSize: 22,
    marginBottom: 5,
  },
  address: {
    color: "#666",
    fontSize: 16,
  },
  ekly: {
    color: "red",
    fontSize: 22,
    marginBottom: 5,
  },
});


{/* <FlatList
                              numColumns={1}
                              horizontal={false}
                              data={meals}
                              renderItem={({ item }) => (
                                    // <TouchableOpacity
                                    //   // style={{marginRight:10}}
                                    //   onPress={() => props.navigation.navigate('PlacesDetailScreen', {
                                    //     placeTitle: item.name,
                                    //     placeId: item.id,
                                    //     Fphone: item.Fphone,
                                    //     Mphone: item.Mphone,
                                    //     addedByName: item.addedByName,
                                    //     creation: item.creation,
                                    //     ekly: item.ekly,
                                    //     code: item.code
                                    //   })}>
                                    //   <Text style={styles.text}>{item.name}</Text>
                                    // </TouchableOpacity>
                                    <TouchableOpacity onPress={() => props.navigation.navigate('PlacesDetailScreen', {
                                          placeTitle: item.name,
                                          placeId: item.id,
                                          Fphone: item.Fphone,
                                          Mphone: item.Mphone,
                                          addedByName: item.addedByName,
                                          creation: item.creation,
                                          ekly: item.ekly,
                                          code: item.code
                                    })} style={styles.placeItem}>

                                          <Image style={styles.imageCard} source={{
                                                uri:
                                                      // props.imageUri ? props.imageUri :
                                                      'https://img.freepik.com/free-vector/person-avatar-design_24877-38137.jpg?w=740'
                                          }} />
                                          <View style={styles.infoContainer}>
                                                <Text style={styles.title}>الأسم:  {item.name}</Text>
                                                {/* <Text style={styles.address}>{props.address}</Text> */}

{/* {meals.map((v, index) => {
          return (
          //   <FlatList
          //   data={v}
          //   keyExtractor={v => v.id}
          //   renderItem={v => (
          //     <ChildCard
          //       key={index}
          //       // image={v.photo}
          //       name={v.name}
          //       // address={v.address}
          //       // onSelect={() => {
          //       //   props.navigation.navigate('PlaceDetail', {
          //       //     placeTitle: itemData.item.title,
          //       //     placeId: itemData.item.id
          //       //   });
          //       // }}
          //     />
          //   )}
          // />
          
                  <ChildCard
                    key={index}
                    name={v.name}
                    creation={v.creation}
                    Fphone={v.Fphone}
                    Mphone={v.Mphone}
                    code={v.code}
                    ekly={v.ekly}
                    onSelect={() => {
                      props.navigation.navigate('PlacesDetailScreen', {
                        placeTitle: v.name,
                        placeId: v.id,
                        Fphone: v.Fphone,
                        Mphone: v.Mphone,
                        addedByName: v.addedByName,
                        creation: v.creation,
                        ekly: v.ekly,
                        code: v.code
                      });
                    }}
                  />
          )
          
        })
        } */}

// useEffect(() => {
//       if (isUser === null) {

//             alert('You Have To Log In First To Post Your Add')

//       } else if (isUser) {

//             let isMounted = true;
//             async function FetchDataFromFirebase() {
//                   // if (meals) {

//                   let mealsData = [];
//                   firebase
//                         .firestore()
//                         .collection("kids")
//                         .doc(firebase.auth().currentUser.uid)
//                         .get()
//                         .then((snapshot) => {
//                               if (snapshot.exists) {

//                                     // snapshot.docChanges().forEach((data) => {
//                                     let product = snapshot.data();
//                                     // product.id = data.doc.id;
//                                     mealsData.push(product);
//                                     console.log(product)
//                                     // console.log(mealsData);
//                                     // });
//                                     setMeals(mealsData);
//                                     setLoading(false);
//                               }
//                         })
//                   // } else {
//                   //       alert('You Have To Log In First To Post Your Add')
//                   // }
//                   // .orderBy('creation', 'desc')

//                   // .where('grade', '==', this.state.KhademGrade)
//                   // .where('grade', '==', String(KhademGrade))
//                   // .onSnapshot((snapshot) => {
//                   //       if (isMounted) {

//                   //             snapshot.docChanges().forEach((data) => {
//                   //                   let product = data.doc.data();
//                   //                   product.id = data.doc.id;
//                   //                   mealsData.push(product);
//                   //                   // console.log(mealsData);
//                   //             });
//                   //             setMeals(mealsData);
//                   //             setLoading(false);
//                   //       }
//                   // })
//             }
//             FetchDataFromFirebase()
//             return () => {
//                   isMounted = false
//             }
//       }
// }, [props.route.params?.id, meals]);

// .onSnapshot((snapshot) => {
//       if (snapshot.exists) {

//             snapshot.docChanges().forEach((data) => {
//                   let product = data
//                         // snapshot.data();
//             product.id = data.doc.id;
//             mealsData.push(product);
//             console.log(product)
//             // console.log(mealsData);
//             // });
//             setMeals(mealsData);
//                   setLoading(false);
//             })
//       }
// })
// .get()
// .then((snapshot) => {
//       if (snapshot.exists) {

//             // snapshot.docChanges().forEach((data) => {
//             let product = snapshot.data();
//             // product.id = data.doc.id;
//             mealsData.push(product);
//             console.log(product)
//             // console.log(mealsData);
//             // });
//             setMeals(mealsData);
//             setLoading(false);
//       }
// })
// return (

//       <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
//             <Text>welcome {data.displayName}</Text>
//             <Text>You Can Edit Your Profile Here</Text>
//       </View>
// )
// setUserInfo(data)