import { View, Text } from 'react-native'
import React from 'react'

const MessagesScreen = () => {
      return (
            <View>
                  <Text>MessagesScreen</Text>
            </View>
      )
}

export default MessagesScreen




// import { View, Text, ScrollView, Image, Pressable } from 'react-native'
// import React from 'react'
// import { FlatList } from 'react-native-web'

// const carouselImages = [
//       require('../assets/banner5.jpg'),
//       require('../assets/banner6.jpg'),
//       require('../assets/banner7.jpg'),
//       require('../assets/banner8.jpg'),
//       require('../assets/banner9.jpg'),
//       require('../assets/banner10.jpg')
// ]
// const MessagesScreen = () => {
//       return (
//             <View>

//                   <FlatList

//                         // ref={flatListRef}
//                         data={carouselImages}
//                         horizontal
//                         keyExtractor={(item, index) => index}
//                         // showsHorizontalScrollIndicator={false}
//                         // flatListRef={React.createRef()}
//                         // snapToAlignment="start"
//                         // decelerationRate={"fast"}
//                         // snapToInterval={Dimensions.get("window").height}
//                         // snapToAlignment="center"
//                         // pagingEnabled
//                         // renderItem={renderProduct}
//                         // bounces={false}
//                         // data={productImageList}
//                         // keyExtractor={(item, index) => item.id}
//                         // decelerationRate={0.8}
//                         // data={product.productImageList ? product.productImageList : null}
//                         // snapToInterval={width}

//                         // onScroll={Animated.event(
//                         //   [{ nativeEvent: { contentOffset: { x: scrollX } } }],
//                         //   { useNativeDriver: false }
//                         // )}
//                         renderItem={({ item, index }) => {
//                               return (
//                                     <Pressable
//                                           key={index}
//                                           style={{
//                                                 width: 500,
//                                                 marginVertical: 5,
//                                                 marginRight: 5,
//                                           }}
//                                           onPress={() => {

//                                           }
//                                                 // props.navigation.navigate("ProductInfo", {
//                                                 //       productId: item.id,
//                                                 // })
//                                           }
//                                     >
//                                           <Image
//                                                 source={item}
//                                                 style={{
//                                                       width: undefined,
//                                                       height: undefined,
//                                                       alignItems: "center",
//                                                       justifyContent: "center",
//                                                       borderRadius: 10,
//                                                       aspectRatio: 1,
//                                                       resizeMode: "cover",
//                                                 }}
//                                           ></Image>
//                                           {/* <View style={{ marginTop: 30, flex: 1 }}>
//                                                 <View>
//                                                       <Text
//                                                             style={{
//                                                                   fontSize: 13,
//                                                                   color: "#000",
//                                                                   fontWeight: "400",
//                                                                   opacity: 0.8,
//                                                             }}
//                                                       >
//                                                             {item?.ShortDescription.length > 80
//                                                                   ? `${item?.ShortDescription.substring(0, 80)}...`
//                                                                   : item?.ShortDescription}
//                                                       </Text>
//                                                 </View>

//                                                 <View
//                                                       style={{
//                                                             flexDirection: "row",
//                                                             right: 4,
//                                                             alignItems: "center",
//                                                       }}
//                                                 >
//                                                       <Text style={{ fontSize: 14, fontWeight: "700" }}>
//                                                             {" "}
//                                                             EGP{" "}
//                                                       </Text>
//                                                       <Text style={{ fontSize: 16, fontWeight: "500" }}>
//                                                             {" "}
//                                                             {" " + item?.Price}
//                                                       </Text>
//                                                 </View>
//                                           </View> */}
//                                     </Pressable>
//                               );
//                         }}
//                   />
//                   {/* <ScrollView
//                         onScrollBeginDrag={console.log('dragged')}
//                         onScrollEndDrag={console.log('dragged end')}
//                         style={{ flex: 1, width: '100%', height: 600 }} horizontal>
//                         {carouselImages.map((item, index) => {
//                               return (
//                                     <Image key={index} source={item} style={{ height: 1000, width: 1000 }} />
//                               )
//                         })}
//                   </ScrollView> */}

//                   {/* <Text>MessagesScreen</Text> */}
//             </View>
//       )
// }

// export default MessagesScreen