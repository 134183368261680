import { View, Text } from 'react-native'
import React from 'react'

const ContactScreen = () => {
      return (
            <View>
                  <Text>ContactScreen</Text>
            </View>
      )
}

export default ContactScreen