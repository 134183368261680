import React, { useEffect, useState, useRef } from 'react';
import { Image, StyleSheet, Button, Text, View, TouchableOpacity, ImageBackground, Dimensions, LogBox } from 'react-native';
// import { createStackNavigator } from '@react-navigation/stack';
import {
      DrawerItem,
      createDrawerNavigator,
      // DrawerContentScrollView,
} from '@react-navigation/drawer';
import * as Animatable from 'react-native-animatable'
import { Feather, AntDesign, MaterialCommunityIcons, Entypo, FontAwesome, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import HomeScreen from './Screens/HomeScreen';
import SellingScreen from './Screens/SellingScreen';
import AddScreen from './Screens/AddScreen';
import ChildImage from './Screens/ChildImage';
import EditScreen from './Screens/EditScreen';
import ProductInfo from './Screens/ProductInfo';
import ContactScreen from './Screens/ContactScreen';
import SettingsScreen from './Screens/SettingsScreen';
import MessagesScreen from './Screens/MessagesScreen';
import ChatScreen from './Screens/ChatScreen';
import ChatsScreen from './Screens/ChatsScreen';
import MyAccount from './Screens/MyAccount';
import RegisterScreen from './Screens/RegisterScreen';
import LoginScreen from './Screens/LoginScreen';
import EditProfile from './Screens/EditProfile';
import Favorites from './Screens/Favorites';
import Following from './Screens/Following';
import firebase from './firebase/firebaseConfig'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import ScreenSize from './components/ScreenSize';
import ProductsList from './Screens/ProductsList'
import ForgotPasswordScreen from './Screens/ForgotPasswordScreen'
// import SplashScreen from './Screens/SplashScreen';
// import MyCart from './Screens/MyCart';
// import MapScreen from './Screens/MapScreen';

const Drawer = createDrawerNavigator();

export default function DrawerMenu() {
      const styles = useStyles()
      const rubberBandAnimeRef = useRef()
      const screenSize = ScreenSize()
      const [userInfo, setUserInfo] = useState(null)

      useEffect(() => {
            onAuthStateChanged(getAuth(), (data) => {
                  if (data) {
                        // console.log(getAuth()?.currentUser)
                        firebase.firestore().collection('Users').doc(firebase.auth()?.currentUser?.uid).onSnapshot(snapshot => {
                              setUserInfo(snapshot.data())
                              // console.log(snapshot.data())
                        })
                  }
                  setUserInfo(null)
            })
      }, [])
      const DrawerContent = props => {
            return (
                  <View style={{
                        flex: 1,
                        overflow: 'hidden'
                        // backgroundColor: '#000'
                        // justifyContent: 'center'
                  }}>

                        <LinearGradient style={{ overflow: 'hidden', justifyContent: 'space-between', height: '100%' }} colors={['#12343b', '#2d545e', '#c89666', '#e1b382']}>
                              <View style={{
                                    alignItems: 'center',
                                    marginTop: '5%',
                                    // flex: 1 / 4,
                                    // backgroundColor: 'red'
                                    // justifyContent: 'center',
                                    // paddingTop: screenSize.height / 50,
                                    // width: '100%',
                                    // height:'25%'
                                    // alignItems: 'center',
                                    // paddingTop: screenSize.height / 50,
                                    // justifyContent: 'center'
                              }} >
                                    <Image
                                          style={styles.avatar}
                                          source={{ uri: userInfo === null ? 'https://img.freepik.com/free-icon/user_318-159711.jpg' : userInfo?.photoURL, }}>
                                    </Image>

                                    <Text style={{ color: '#A8A7A7', paddingTop: 10 }}>
                                          {userInfo === null ? 'userName' : userInfo?.displayName}
                                    </Text>

                                    <Text style={{ color: '#A8A7A7' }}>
                                          {userInfo === null ? 'user@mail.com' : userInfo?.email}
                                    </Text>
                              </View>

                              <View style={{
                                    flex: 1 / 2,
                                    // height: '100%',
                                    // // paddingTop: screenSize.height / 35,
                                    // alignItems: 'center',
                                    justifyContent: 'center',
                                    // backgroundColor: 'green'
                              }}>
                                    <DrawerItem
                                          label="Home"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('HomeScreen')}
                                          icon={() => <AntDesign name="home" color="white" size={ScreenSize().ratio(50)} />}
                                    />
                                    <DrawerItem
                                          label="Selling"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('AddScreen')}
                                          icon={() => <AntDesign name="pluscircleo" color="white" size={ScreenSize().ratio(50)} />}
                                    />
                                    <DrawerItem
                                          label="Chats"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('ChatsScreen')}
                                          icon={() => <Ionicons name="chatbubbles-outline" color="white" size={ScreenSize().ratio(50)} />}
                                    />
                                    <DrawerItem
                                          label="Favorites"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('Favorites')}
                                          icon={() => <MaterialIcons name="favorite-outline" size={ScreenSize().ratio(50)} color="white" />}
                                    />
                                    <DrawerItem
                                          label="Following"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('Following')}
                                          icon={() => <MaterialIcons name="follow-the-signs" size={ScreenSize().ratio(50)} color="white" />}
                                    />
                                    <DrawerItem
                                          label="Saved Searches"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('SavedSearches')}
                                          icon={() => <MaterialCommunityIcons name="book-search-outline" size={ScreenSize().ratio(50)} color="white" />}
                                    />
                                    {/* <DrawerItem
                                                label="Messages"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('MessagesScreen')}
                                                icon={() => <AntDesign name="message1" color="white" size={ScreenSize().ratio(50)} />}
                                          /> */}
                                    <DrawerItem
                                          label="Settings"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('SettingsScreen')}
                                          icon={() => <AntDesign name="setting" color="white" size={ScreenSize().ratio(50)} />}
                                    />
                                    <DrawerItem
                                          label="Contact us"
                                          labelStyle={styles.drawerLabel}
                                          onPress={() => props.navigation.navigate('ContactScreen')}
                                          icon={() => <AntDesign name="phone" color="white" size={ScreenSize().ratio(50)} />}
                                    />
                              </View>

                              <View style={{
                                    // backgroundColor: 'blue'
                                    // flex: 1 / 10,
                                    // bottom: 0,
                                    // position: 'absolute'
                                    // // marginTop: 25,
                              }} >
                                    {/* <View style={{ bottom: 0, position: 'absolute' }} > */}
                                    <DrawerItem
                                          style={{}}
                                          label="Logout"
                                          labelStyle={{ color: 'red' }}
                                          icon={() => <AntDesign name="logout" color="red" size={20} />}
                                          onPress={() => {
                                                firebase.auth().signOut()
                                                firebase
                                                      .firestore()
                                                      .collection('activityLog')
                                                      .doc(getAuth()?.currentUser?.uid)
                                                      .collection('activityLog')
                                                      .add({
                                                            signOut: firebase.firestore.FieldValue.serverTimestamp(),
                                                      })
                                          }
                                          } />
                              </View>
                        </LinearGradient>

                        {/* <LinearGradient style={{ flex: 1 }} colors={['#12343b', '#2d545e', '#c89666', '#e1b382']}>
                              <View>
                                    <View style={{
                                          alignItems: 'center',
                                          paddingTop: screenSize.height / 50,
                                          justifyContent: 'center'
                                    }} >
                                          <Image
                                                style={styles.avatar}
                                                source={{ uri: userInfo === null ? 'https://img.freepik.com/free-icon/user_318-159711.jpg' : userInfo?.photoURL, }}>
                                          </Image>

                                          <Text style={{ color: '#A8A7A7', paddingTop: 10 }}>
                                                {userInfo === null ? 'userName' : userInfo?.displayName}
                                          </Text>

                                          <Text style={{ color: '#A8A7A7' }}>
                                                {userInfo === null ? 'user@mail.com' : userInfo?.email}
                                          </Text>
                                    </View>
                                    <View style={{
                                          height: '100%',
                                          // paddingTop: screenSize.height / 35,
                                          // alignItems: 'center',
                                          justifyContent: 'center'
                                    }}>
                                          <DrawerItem
                                                label="Home"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('HomeScreen')}
                                                icon={() => <AntDesign name="home" color="white" size={screenSize.height / 35} />}
                                          />
                                          <DrawerItem
                                                label="Selling"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('AddScreen')}
                                                icon={() => <AntDesign name="pluscircleo" color="white" size={screenSize.height / 35} />}
                                          />
                                          <DrawerItem
                                                label="Chats"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('ChatsScreen')}
                                                icon={() => <Ionicons name="chatbubbles-outline" color="white" size={screenSize.height / 35} />}
                                          />
                                          <DrawerItem
                                                label="Favorites"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('Favorites')}
                                                icon={() => <MaterialIcons name="favorite-outline" size={screenSize.height / 35} color="white" />}
                                          />
                                          <DrawerItem
                                                label="Following"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('Following')}
                                                icon={() => <MaterialIcons name="follow-the-signs" size={screenSize.height / 35} color="white" />}
                                          />
                                          <DrawerItem
                                                label="Saved Searches"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('SavedSearches')}
                                                icon={() => <MaterialCommunityIcons name="book-search-outline" size={screenSize.height / 35} color="white" />}
                                          />
                                          <DrawerItem
                                                label="Settings"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('SettingsScreen')}
                                                icon={() => <AntDesign name="setting" color="white" size={screenSize.height / 35} />}
                                          />
                                          <DrawerItem
                                                label="Contact us"
                                                labelStyle={styles.drawerLabel}
                                                onPress={() => props.navigation.navigate('ContactScreen')}
                                                icon={() => <AntDesign name="phone" color="white" size={screenSize.height / 35} />}
                                          />
                                    </View>
                                    <View style={{
                                          // marginTop: 25,
                                          bottom: 0, position: 'absolute'
                                    }} >
                                          <DrawerItem
                                                style={{}}
                                                label="Logout"
                                                labelStyle={{ color: 'red' }}
                                                icon={() => <AntDesign name="logout" color="red" size={20} />}
                                                onPress={() => {
                                                      firebase.auth().signOut()
                                                      firebase
                                                            .firestore()
                                                            .collection('activityLog')
                                                            .doc(getAuth()?.currentUser?.uid)
                                                            .collection('activityLog')
                                                            .add({
                                                                  signOut: firebase.firestore.FieldValue.serverTimestamp(),
                                                            })
                                                }
                                                } />
                                    </View>
                              </View>
                        </LinearGradient> */}
                  </View>
            );
      };


      return (
            <Drawer.Navigator
                  sceneContainerStyle={{ backgroundColor: '#fff' }}
                  drawerContent={DrawerContent}
                  screenOptions={({ navigation }) => ({
                        headerShown: true,
                        headerTitle: null,
                        headerStyle: { backgroundColor: '#133337', paddingHorizontal: screenSize.isWide ? '9.2%' : '0%' },
                        headerLeft: () => (
                              <View>
                                    {screenSize.isWide ?
                                          <View style={{
                                                flexDirection: 'row',
                                                // paddingLeft: screenSize.width / 11,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                          }}>
                                                <TouchableOpacity style={{
                                                      // paddingRight: 15
                                                }} onPress={() => navigation.openDrawer()}>
                                                      <Feather name="menu" size={screenSize.ratio(22)} color="#EFF5F5" />
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => navigation.navigate('HomeScreen')}>
                                                      <Text style={{ fontSize: screenSize.ratio(22), fontFamily: 'BabyAletha', color: '#EFF5F5', marginLeft: 6 }}>
                                                            Lap Market
                                                      </Text>
                                                </TouchableOpacity>
                                          </View>
                                          :
                                          <View style={{
                                                flexDirection: 'row',
                                                paddingLeft: screenSize.width / 30,
                                                alignItems: 'center', justifyContent: 'center'
                                          }}>

                                                <TouchableOpacity style={{ paddingRight: 8 }} onPress={() => navigation.openDrawer()}>
                                                      <Feather name="menu" size={screenSize.width / 15} color="#EFF5F5" style={{}} />
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => navigation.navigate('HomeScreen')}>
                                                      <Text style={{ fontSize: screenSize.width / 10, fontFamily: 'BabyAletha', color: '#EFF5F5' }}>
                                                            Lap Market
                                                      </Text>
                                                </TouchableOpacity>
                                          </View>
                                    }
                              </View>
                        ),

                        headerRight: () => (
                              <View>
                                    {screenSize.isWide ?
                                          <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // width: '100%',
                                                // backgroundColor: 'blue'
                                          }}>
                                                <Animatable.View
                                                      style={{
                                                            marginRight: 15,
                                                            // alignItems: 'center',
                                                            // justifyContent: 'center',
                                                            // width: screenSize.ratio(35), backgroundColor: 'red'
                                                      }}
                                                      ref={rubberBandAnimeRef}>
                                                      <LinearGradient
                                                            colors={['#0a4f57', '#44aab8']}
                                                            style={{
                                                                  // paddingVertical: 10,
                                                                  // paddingHorizontal: screenSize.width / 30,
                                                                  width: screenSize.ratio(20),
                                                                  borderRadius: 4,
                                                                  elevation: 5,
                                                                  shadowColor: '#000',
                                                                  shadowOpacity: 0.25,
                                                                  shadowRadius: 3.5,
                                                                  shadowOffset: { width: 2, height: 2 }
                                                            }}>
                                                            <TouchableOpacity onPress={() => {
                                                                  navigation.navigate('AddScreen')
                                                                  rubberBandAnimeRef?.current?.rubberBand(800)
                                                            }}>

                                                                  <Text style={styles.animeButtonText}>
                                                                        Sell
                                                                  </Text>

                                                            </TouchableOpacity>
                                                      </LinearGradient>
                                                </Animatable.View>

                                                <TouchableOpacity
                                                      onPress={() => navigation.navigate('MyAccount', { profileID: userInfo?.uid })}>
                                                      {userInfo ?
                                                            <Image
                                                                  source={{ uri: userInfo.photoURL }}
                                                                  style={{
                                                                        borderRadius: 50, borderWidth: 1, borderColor: 'white',
                                                                        aspectRatio: 1,
                                                                        // width: '100%',
                                                                        // width: screenSize.width / 20,
                                                                        // height: 'auto'
                                                                        width: screenSize.ratio(24.7)
                                                                  }}>
                                                            </Image>
                                                            :
                                                            <View >
                                                                  <MaterialCommunityIcons
                                                                        name="account"
                                                                        style={{
                                                                              fontSize: screenSize.width / 30,
                                                                              color: '#EFF5F5',
                                                                              borderRadius: 10,
                                                                        }} />
                                                            </View>}
                                                </TouchableOpacity>
                                          </View>
                                          :
                                          <View style={{ paddingRight: screenSize.width / 70, flexDirection: 'row', }}>

                                                <Animatable.View style={{ paddingRight: 11, alignItems: 'center', justifyContent: 'center' }} ref={rubberBandAnimeRef}>
                                                      <LinearGradient
                                                            style={{
                                                                  paddingVertical: screenSize.ratio(1000),
                                                                  paddingHorizontal: screenSize.ratio(500),
                                                                  borderRadius: 4,
                                                                  elevation: 5,
                                                                  shadowColor: '#000',
                                                                  shadowOpacity: 0.25,
                                                                  shadowRadius: 3.5,
                                                                  shadowOffset: { width: 2, height: 2 },
                                                            }}
                                                            colors={['#0a4f57', '#44aab8']}
                                                      // colors={['#24C6DC', '#514A9D']}
                                                      >
                                                            <TouchableOpacity onPress={() => {
                                                                  navigation.navigate('AddScreen')
                                                                  rubberBandAnimeRef?.current?.rubberBand(800)
                                                            }}>
                                                                  <Text style={styles.animeButtonText}>
                                                                        Sell
                                                                  </Text>
                                                            </TouchableOpacity>
                                                      </LinearGradient>
                                                </Animatable.View>

                                                <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.navigate('MyAccount', { profileID: userInfo?.uid })}>
                                                      {userInfo ?
                                                            <Image source={{ uri: userInfo.photoURL }} style={{ borderRadius: 50, borderWidth: 1, borderColor: 'white', height: screenSize.height / 18, width: screenSize.height / 18 }}>
                                                            </Image>
                                                            :
                                                            <View >
                                                                  <MaterialCommunityIcons
                                                                        name="account"
                                                                        style={{
                                                                              fontSize: screenSize.width / 12,
                                                                              color: '#EFF5F5',
                                                                              borderRadius: 10,
                                                                        }} />
                                                            </View>}
                                                </TouchableOpacity>
                                          </View>}
                              </View>
                        ),
                  })}>

                  <Drawer.Screen name="HomeScreen" component={HomeScreen} />
                  <Drawer.Screen name="SellingScreen" component={SellingScreen} />
                  <Drawer.Screen name="ChildImage" component={ChildImage} />
                  <Drawer.Screen name="ProductInfo" component={ProductInfo} />
                  <Drawer.Screen name="ContactScreen" component={ContactScreen} />
                  <Drawer.Screen name="MessagesScreen" component={MessagesScreen} />
                  <Drawer.Screen name="ChatScreen" component={ChatScreen} />
                  <Drawer.Screen name="Favorites" component={Favorites} />
                  <Drawer.Screen name="Following" component={Following} />
                  <Drawer.Screen name="ProductsList" component={ProductsList} />
                  <Drawer.Screen name="ChatsScreen" component={ChatsScreen} />
                  {/* <Drawer.Screen name="SplashScreen" component={SplashScreen} /> */}
                  {/* <Drawer.Screen name="MyCart" component={MyCart} /> */}
                  {/* <Drawer.Screen name="MapScreen" component={MapScreen} /> */}
                  <Drawer.Screen name="MyAccount" component={MyAccount} />
                  <Drawer.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
                  <Drawer.Screen options={{ unmountOnBlur: true }} name="SettingsScreen" component={SettingsScreen} />
                  <Drawer.Screen options={{ unmountOnBlur: true }} name="EditScreen" component={EditScreen} />
                  <Drawer.Screen options={{ unmountOnBlur: true }} name="EditProfile" component={EditProfile} />
                  <Drawer.Screen options={{ unmountOnBlur: true }} name="AddScreen" component={AddScreen} />
                  <Drawer.Screen options={{ headerShown: false, unmountOnBlur: true }} name="RegisterScreen" component={RegisterScreen} />
                  <Drawer.Screen options={{ headerShown: false, unmountOnBlur: true }} name="LoginScreen" component={LoginScreen} />
            </Drawer.Navigator>
      );
};

function useStyles() {
      return StyleSheet.create({
            // stack: {
            //       flex: 1,
            //       shadowColor: '#FFF',
            //       shadowOffset: { width: 0, height: 8 },
            //       shadowOpacity: 0.44,
            //       shadowRadius: 10.32,
            //       elevation: 5,
            // },
            // drawerStyles: { flex: 1, width: '50%', backgroundColor: 'transparent' },
            drawerItem: { alignItems: 'flex-start', marginVertical: 0 },
            drawerLabel: { color: 'white', marginLeft: -16, marginVertical: -7 },
            avatar: {
                  width: ScreenSize().ratio(8),
                  height: ScreenSize().ratio(8),

                  // width: (ScreenSize().height / 10) + (ScreenSize().width / 28),
                  // // width: ScreenSize().isWide ? ScreenSize().width / 9 : ScreenSize().width / 3,
                  // height: (ScreenSize().height / 10) + (ScreenSize().width / 28),
                  // height: ScreenSize().isWide ? ScreenSize().width / 9 : ScreenSize().width / 3,
                  borderRadius: 1000,
                  aspectRatio: 1,
                  resizeMode: 'contain',
                  // backgroundColor: 'aqua'
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // resizeMode: 'cover',

            },
            animeButtonText: {
                  color: '#fff',
                  fontWeight: '800',
                  fontSize: ScreenSize().ratio(45),
                  alignSelf: 'center',
                  padding: 5,
                  paddingHorizontal: 5,
            }
            // animeButton: {
            //       backgroundColor: '#0652DD',
            //       paddingHorizontal: 40,
            //       paddingVertical: 10,
            //       borderRadius: 4,
            //       elevation: 5,
            //       shadowColor: '#000',
            //       shadowOffset: {
            //             width: 2,
            //             height: 2
            //       },
            //       shadowOpacity: 0.25,
            //       shadowRadius: 3.5,
            // },
      })
}




// import React, { useEffect, useState, useRef } from 'react';
// import { Image, StyleSheet, Button, Text, View, TouchableOpacity, ImageBackground, Dimensions, LogBox } from 'react-native';
// // import { createStackNavigator } from '@react-navigation/stack';
// import {
//       DrawerItem,
//       createDrawerNavigator,
//       // DrawerContentScrollView,
// } from '@react-navigation/drawer';
// import * as Animatable from 'react-native-animatable'
// import { Feather, AntDesign, MaterialCommunityIcons, Entypo, FontAwesome, Ionicons, MaterialIcons } from '@expo/vector-icons';
// import { LinearGradient } from 'expo-linear-gradient';
// import HomeScreen from './Screens/HomeScreen';
// import SellingScreen from './Screens/SellingScreen';
// import AddScreen from './Screens/AddScreen';
// import ChildImage from './Screens/ChildImage';
// import EditScreen from './Screens/EditScreen';
// import ProductInfo from './Screens/ProductInfo';
// import ContactScreen from './Screens/ContactScreen';
// import SettingsScreen from './Screens/SettingsScreen';
// import MessagesScreen from './Screens/MessagesScreen';
// import ChatScreen from './Screens/ChatScreen';
// import ChatsScreen from './Screens/ChatsScreen';
// import MyAccount from './Screens/MyAccount';
// import RegisterScreen from './Screens/RegisterScreen';
// import LoginScreen from './Screens/LoginScreen';
// import EditProfile from './Screens/EditProfile';
// import Favorites from './Screens/Favorites';
// import Following from './Screens/Following';
// import firebase from './firebase/firebaseConfig'
// import { getAuth, onAuthStateChanged } from 'firebase/auth'
// import ScreenSize from './components/ScreenSize';
// import ProductsList from './Screens/ProductsList'
// // import SplashScreen from './Screens/SplashScreen';
// // import MyCart from './Screens/MyCart';
// // import MapScreen from './Screens/MapScreen';

// const Drawer = createDrawerNavigator();

// export default function DrawerMenu() {
//       // const styles = useStyles()
//       // const rubberBandAnimeRef = useRef()
//       // const screenSize = ScreenSize()
//       // const [userInfo, setUserInfo] = useState(null)

//       // useEffect(() => {
//       //       onAuthStateChanged(getAuth(), (data) => {
//       //             if (data) {
//       //                   // console.log(getAuth()?.currentUser)
//       //                   firebase.firestore().collection('Users').doc(firebase.auth()?.currentUser?.uid).onSnapshot(snapshot => {
//       //                         setUserInfo(snapshot.data())
//       //                         // console.log(snapshot.data())
//       //                   })
//       //             }
//       //             setUserInfo(null)
//       //       })
//       // }, [])


//       // const DrawerContent = props => {
//       //       return (
//       //             <View style={{
//       //                   // height: 855
//       //                   height: screenSize.height
//       //                   // flex: 1,
//       //                   // justifyContent: 'center'
//       //             }}>
//       //                   {/* <DrawerContentScrollView contentContainerStyle={{ flex: 1 }}> */}
//       //                   {/* <LinearGradient
//       //                         style={{ height: '100%' }}
//       //                         // style={{ flex: 1 }}
//       //                         colors={['#12343b', '#2d545e', '#c89666', '#e1b382']}> */}
//       //                   <View>
//       //                         <View style={{
//       //                               alignItems: 'center',
//       //                               paddingTop: screenSize.height / 50
//       //                         }} >
//       //                               <Image
//       //                                     style={styles.avatar}
//       //                                     source={{ uri: userInfo === null ? 'https://img.freepik.com/free-icon/user_318-159711.jpg' : userInfo?.photoURL, }}>
//       //                               </Image>

//       //                               <Text style={{ color: '#A8A7A7', paddingTop: 10 }}>
//       //                                     {userInfo === null ? 'userName' : userInfo?.displayName}
//       //                               </Text>

//       //                               <Text style={{ color: '#A8A7A7' }}>
//       //                                     {userInfo === null ? 'user@mail.com' : userInfo?.email}
//       //                               </Text>
//       //                         </View>
//       //                         <View style={{
//       //                               paddingTop: screenSize.height / 35,
//       //                               // alignItems: 'center',
//       //                               justifyContent: 'center'
//       //                         }}>
//       //                               <DrawerItem
//       //                                     label="Home"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('HomeScreen')}
//       //                                     icon={() => <AntDesign name="home" color="white" size={screenSize.height / 35} />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Selling"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('AddScreen')}
//       //                                     icon={() => <AntDesign name="pluscircleo" color="white" size={screenSize.height / 35} />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Chats"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('ChatsScreen')}
//       //                                     icon={() => <Ionicons name="chatbubbles-outline" color="white" size={screenSize.height / 35} />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Favorites"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('Favorites')}
//       //                                     icon={() => <MaterialIcons name="favorite-outline" size={screenSize.height / 35} color="white" />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Following"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('Following')}
//       //                                     icon={() => <MaterialIcons name="follow-the-signs" size={screenSize.height / 35} color="white" />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Saved Searches"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('SavedSearches')}
//       //                                     icon={() => <MaterialCommunityIcons name="book-search-outline" size={screenSize.height / 35} color="white" />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Messages"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('MessagesScreen')}
//       //                                     icon={() => <AntDesign name="message1" color="white" size={screenSize.height / 35} />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Settings"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('SettingsScreen')}
//       //                                     icon={() => <AntDesign name="setting" color="white" size={screenSize.height / 35} />}
//       //                               />
//       //                               <DrawerItem
//       //                                     label="Contact us"
//       //                                     labelStyle={styles.drawerLabel}
//       //                                     onPress={() => props.navigation.navigate('ContactScreen')}
//       //                                     icon={() => <AntDesign name="phone" color="white" size={screenSize.height / 35} />}
//       //                               />
//       //                         </View>
//       //                   </View>
//       //                   <View style={{ bottom: 0, position: 'absolute' }} >
//       //                         <DrawerItem
//       //                               label="Logout"
//       //                               labelStyle={{ color: 'red' }}
//       //                               icon={() => <AntDesign name="logout" color="red" size={20} />}
//       //                               onPress={() => {
//       //                                     firebase.auth().signOut()
//       //                                     firebase
//       //                                           .firestore()
//       //                                           .collection('activityLog')
//       //                                           .doc(getAuth()?.currentUser?.uid)
//       //                                           .collection('activityLog')
//       //                                           .add({
//       //                                                 signOut: firebase.firestore.FieldValue.serverTimestamp(),
//       //                                           })
//       //                               }
//       //                               } />
//       //                   </View>
//       //                   {/* </LinearGradient> */}
//       //                   {/* </DrawerContentScrollView> */}
//       //             </View>
//       //       );
//       // };


//       return (
//             <Drawer.Navigator
//             // drawerContent={DrawerContent}
//             // screenOptions={({ navigation }) => ({
//             //       headerShown: true,
//             //       headerTitle: null,
//             //       headerStyle: { backgroundColor: '#133337', paddingHorizontal: screenSize.isWide ? '9.2%' : '0%' },
//             //       headerLeft: () => (
//             //             <View>
//             //                   {screenSize.isWide ?
//             //                         <View style={{
//             //                               flexDirection: 'row',
//             //                               // paddingLeft: screenSize.width / 11,
//             //                               alignItems: 'center',
//             //                               justifyContent: 'center'
//             //                         }}>
//             //                               <TouchableOpacity style={{
//             //                                     // paddingRight: 15
//             //                               }} onPress={() => navigation.openDrawer()}>
//             //                                     <Feather name="menu" size={screenSize.ratio(22)} color="#EFF5F5" />
//             //                               </TouchableOpacity>

//             //                               <TouchableOpacity onPress={() => navigation.navigate('HomeScreen')}>
//             //                                     <Text style={{ fontSize: screenSize.ratio(22), fontFamily: 'BabyAletha', color: '#EFF5F5', marginLeft: 6 }}>
//             //                                           Lap Market
//             //                                     </Text>
//             //                               </TouchableOpacity>
//             //                         </View>
//             //                         :
//             //                         <View style={{
//             //                               flexDirection: 'row',
//             //                               paddingLeft: screenSize.width / 30,
//             //                               alignItems: 'center', justifyContent: 'center'
//             //                         }}>

//             //                               <TouchableOpacity style={{ paddingRight: 8 }} onPress={() => navigation.openDrawer()}>
//             //                                     <Feather name="menu" size={screenSize.width / 15} color="#EFF5F5" style={{}} />
//             //                               </TouchableOpacity>

//             //                               <TouchableOpacity onPress={() => navigation.navigate('HomeScreen')}>
//             //                                     <Text style={{ fontSize: screenSize.width / 10, fontFamily: 'BabyAletha', color: '#EFF5F5' }}>
//             //                                           Lap Market
//             //                                     </Text>
//             //                               </TouchableOpacity>
//             //                         </View>
//             //                   }
//             //             </View>
//             //       ),

//             //       headerRight: () => (
//             //             <View>
//             //                   {screenSize.isWide ?
//             //                         <View style={{
//             //                               flexDirection: 'row',
//             //                               alignItems: 'center',
//             //                               justifyContent: 'center',
//             //                               // width: '100%',
//             //                               // backgroundColor: 'blue'
//             //                         }}>
//             //                               <Animatable.View
//             //                                     style={{
//             //                                           marginRight: 15,
//             //                                           // alignItems: 'center',
//             //                                           // justifyContent: 'center',
//             //                                           // width: screenSize.ratio(35), backgroundColor: 'red'
//             //                                     }}
//             //                                     ref={rubberBandAnimeRef}>
//             //                                     <LinearGradient
//             //                                           colors={['#0a4f57', '#44aab8']}
//             //                                           style={{
//             //                                                 // paddingVertical: 10,
//             //                                                 // paddingHorizontal: screenSize.width / 30,
//             //                                                 width: screenSize.ratio(20),
//             //                                                 borderRadius: 4,
//             //                                                 elevation: 5,
//             //                                                 shadowColor: '#000',
//             //                                                 shadowOpacity: 0.25,
//             //                                                 shadowRadius: 3.5,
//             //                                                 shadowOffset: { width: 2, height: 2 }
//             //                                           }}>
//             //                                           <TouchableOpacity onPress={() => {
//             //                                                 navigation.navigate('AddScreen')
//             //                                                 rubberBandAnimeRef?.current?.rubberBand(800)
//             //                                           }}>

//             //                                                 <Text style={styles.animeButtonText}>
//             //                                                       Sell
//             //                                                 </Text>

//             //                                           </TouchableOpacity>
//             //                                     </LinearGradient>
//             //                               </Animatable.View>

//             //                               <TouchableOpacity
//             //                                     onPress={() => navigation.navigate('MyAccount', { profileID: userInfo?.uid })}>
//             //                                     {userInfo ?
//             //                                           <Image
//             //                                                 source={{ uri: userInfo.photoURL }}
//             //                                                 style={{
//             //                                                       borderRadius: 50, borderWidth: 1, borderColor: 'white',
//             //                                                       aspectRatio: 1,
//             //                                                       // width: '100%',
//             //                                                       // width: screenSize.width / 20,
//             //                                                       // height: 'auto'
//             //                                                       width: screenSize.ratio(24.7)
//             //                                                 }}>
//             //                                           </Image>
//             //                                           :
//             //                                           <View >
//             //                                                 <MaterialCommunityIcons
//             //                                                       name="account"
//             //                                                       style={{
//             //                                                             fontSize: screenSize.width / 30,
//             //                                                             color: '#EFF5F5',
//             //                                                             borderRadius: 10,
//             //                                                       }} />
//             //                                           </View>}
//             //                               </TouchableOpacity>
//             //                         </View>
//             //                         :
//             //                         <View style={{ paddingRight: screenSize.width / 70, flexDirection: 'row', }}>

//             //                               <Animatable.View style={{ paddingRight: 11, alignItems: 'center', justifyContent: 'center' }} ref={rubberBandAnimeRef}>
//             //                                     <LinearGradient
//             //                                           style={{
//             //                                                 paddingVertical: 10,
//             //                                                 paddingHorizontal: screenSize.width / 30,
//             //                                                 borderRadius: 4,
//             //                                                 elevation: 5,
//             //                                                 shadowColor: '#000',
//             //                                                 shadowOpacity: 0.25,
//             //                                                 shadowRadius: 3.5,
//             //                                                 shadowOffset: { width: 2, height: 2 },
//             //                                           }}
//             //                                           colors={['#0a4f57', '#44aab8']}
//             //                                     // colors={['#24C6DC', '#514A9D']}
//             //                                     >
//             //                                           <TouchableOpacity onPress={() => {
//             //                                                 navigation.navigate('AddScreen')
//             //                                                 rubberBandAnimeRef?.current?.rubberBand(800)
//             //                                           }}>
//             //                                                 <Text style={styles.animeButtonText}>
//             //                                                       Sell
//             //                                                 </Text>
//             //                                           </TouchableOpacity>
//             //                                     </LinearGradient>
//             //                               </Animatable.View>

//             //                               <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.navigate('MyAccount', { profileID: userInfo?.uid })}>
//             //                                     {userInfo ?
//             //                                           <Image source={{ uri: userInfo.photoURL }} style={{ borderRadius: 50, borderWidth: 1, borderColor: 'white', height: screenSize.height / 18, width: screenSize.height / 18 }}>
//             //                                           </Image>
//             //                                           :
//             //                                           <View >
//             //                                                 <MaterialCommunityIcons
//             //                                                       name="account"
//             //                                                       style={{
//             //                                                             fontSize: screenSize.width / 12,
//             //                                                             color: '#EFF5F5',
//             //                                                             borderRadius: 10,
//             //                                                       }} />
//             //                                           </View>}
//             //                               </TouchableOpacity>
//             //                         </View>}
//             //             </View>
//             //       ),
//             // })}
//             >

//                   <Drawer.Screen name="HomeScreen" component={HomeScreen} />
//                   {/* <Drawer.Screen name="SellingScreen" component={SellingScreen} />
//                   <Drawer.Screen name="ChildImage" component={ChildImage} />
//                   <Drawer.Screen name="ProductInfo" component={ProductInfo} />
//                   <Drawer.Screen name="ContactScreen" component={ContactScreen} />
//                   <Drawer.Screen name="SettingsScreen" component={SettingsScreen} />
//                   <Drawer.Screen name="MessagesScreen" component={MessagesScreen} />
//                   <Drawer.Screen name="ChatScreen" component={ChatScreen} />
//                   <Drawer.Screen name="Favorites" component={Favorites} />
//                   <Drawer.Screen name="Following" component={Following} />
//                   <Drawer.Screen name="ProductsList" component={ProductsList} />
//                   <Drawer.Screen name="ChatsScreen" component={ChatsScreen} />
//                   <Drawer.Screen name="MyAccount" component={MyAccount} />
//                   <Drawer.Screen options={{ unmountOnBlur: true }} name="EditScreen" component={EditScreen} />
//                   <Drawer.Screen options={{ unmountOnBlur: true }} name="EditProfile" component={EditProfile} />
//                   <Drawer.Screen options={{ unmountOnBlur: true }} name="AddScreen" component={AddScreen} />
//                   <Drawer.Screen options={{ headerShown: false, unmountOnBlur: true }} name="RegisterScreen" component={RegisterScreen} />
//                   <Drawer.Screen options={{ headerShown: false, unmountOnBlur: true }} name="LoginScreen" component={LoginScreen} /> */}
//             </Drawer.Navigator>
//       );
// };

// // function useStyles() {
// //       return StyleSheet.create({
// //             // stack: {
// //             //       flex: 1,
// //             //       shadowColor: '#FFF',
// //             //       shadowOffset: { width: 0, height: 8 },
// //             //       shadowOpacity: 0.44,
// //             //       shadowRadius: 10.32,
// //             //       elevation: 5,
// //             // },
// //             // drawerStyles: { flex: 1, width: '50%', backgroundColor: 'transparent' },
// //             drawerItem: { alignItems: 'flex-start', marginVertical: 0 },
// //             drawerLabel: { color: 'white', marginLeft: -16, marginVertical: -7 },
// //             avatar: {
// //                   width: (ScreenSize().height / 10) + (ScreenSize().width / 28),
// //                   // width: ScreenSize().isWide ? ScreenSize().width / 9 : ScreenSize().width / 3,
// //                   height: (ScreenSize().height / 10) + (ScreenSize().width / 28),
// //                   // height: ScreenSize().isWide ? ScreenSize().width / 9 : ScreenSize().width / 3,
// //                   borderRadius: 100,
// //                   aspectRatio: 1,
// //                   // alignItems: 'center',
// //                   // justifyContent: 'center',
// //                   // resizeMode: 'cover',

// //             },
// //             animeButtonText: {
// //                   color: '#fff',
// //                   fontWeight: '800',
// //                   fontSize: ScreenSize().ratio(45),
// //                   alignSelf: 'center',
// //                   padding: 5,
// //                   paddingHorizontal: 5,
// //             }
// //             // animeButton: {
// //             //       backgroundColor: '#0652DD',
// //             //       paddingHorizontal: 40,
// //             //       paddingVertical: 10,
// //             //       borderRadius: 4,
// //             //       elevation: 5,
// //             //       shadowColor: '#000',
// //             //       shadowOffset: {
// //             //             width: 2,
// //             //             height: 2
// //             //       },
// //             //       shadowOpacity: 0.25,
// //             //       shadowRadius: 3.5,
// //             // },
// //       })
// // }


