import { View, Text, FlatList, TouchableOpacity, ScrollView, StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import firebase from "../firebase/firebaseConfig";
import { COLOURS } from '../components/database/Database';
import { Image } from 'react-native';
import ScreenSize from '../components/ScreenSize';
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const Favorites = (props) => {
    const uid = getAuth()?.currentUser?.uid ? getAuth()?.currentUser?.uid : firebase.auth()?.currentUser?.uid
    const [ads, setAds] = useState([])
    const [adsID, setAdsID] = useState([])
    const [product, setproduct] = useState([]);
    const screenSize = ScreenSize()
    useEffect(() => {
        onAuthStateChanged(getAuth(), (data) => {
            if (data) {
                console.log(uid)
                firebase
                    .firestore()
                    .collection("likes")
                    .doc(getAuth()?.currentUser?.uid)
                    // .doc(props.route.params?.userID)
                    .collection('userLikes')
                    .orderBy("LikeTime", "desc")
                    // .doc(props.route.params?.productId)
                    .onSnapshot((snapshot) => {
                        setproduct(snapshot.docs.map((doc) => doc.data()));
                        console.log(snapshot.docs.map((doc) => doc.data()))
                    });
            }
            setproduct([])
        })

        // firebase
        //     .firestore()
        //     .collection('Users')
        //     .doc(routeprofileID === undefined ? data.uid : routeprofileID)
        //     .collection('Ads')
        //     .orderBy("creation", "desc")
        //     .onSnapshot((snapshot) => {
        //         setAds(snapshot.docs.map((doc) => doc.data()))
        //         setsearchAds(snapshot.docs.map((doc) => doc.data()))
        //         setfullMeals(snapshot.docs.map((doc) => doc.data()))
        //     })

        // console.log(firebase.auth().currentUser?.uid)
        // console.log(props.route.params?.userID)
        // firebase.firestore()
        //     .collection('likes')
        //     .doc(props.route.params?.userID)
        //     .collection('userLikes')
        // .get()
        // .then(snapshot=> {
        //     console.log(snapshot.docs.length)
        // })
        // .get().then((snapshot) => {
        //     console.log(snapshot.docs.map((doc) => doc.id)) 
        //     })
        // .onSnapshot((snapshot) => {
        //     setAdsID(snapshot.docs.map((doc) => doc.id))
        //     // setAdsID(snapshot.id)
        //     console.log(snapshot.docs.map((doc) => doc.id))
        // })

        // let adsIDArray = []
        // firebase.firestore()
        //     .collection('Ads')
        //     // .where('id', '==', 'oL2oEwj2cRt2mbjp1XxT')
        //     .where('id', '==', adsID.map(adID => adID))
        //     // .where('id', '==', adsID)
        //     // .orderBy("creation", "desc")
        //     .onSnapshot((snapshot) => {
        //         setAds(snapshot.docs.map((doc) => doc.data()));
        //         console.log(snapshot.docs.map((doc) => doc.data()))
        //     });
    }, [])

    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: ScreenSize().isWide ? '50%' : '100%', alignSelf: 'center' }}>
            <ScrollView style={{ width: '100%', height: '100%' }}>
                {product.length === 0 ? <Text> You Don't Have Any Favorites</Text> : null}
                {product.map((data, index) => {
                    return (
                        <TouchableOpacity
                            onPress={() => props.navigation.navigate("ProductInfo", { productId: data.id })}
                            key={index}
                            style={{
                                width: '98%',
                                height: screenSize.height / 6,
                                marginVertical: 5,
                                marginHorizontal: 5,
                                flexDirection: 'row',
                                backgroundColor: '#d0d0d0',
                                borderRadius: 20,
                                alignItems: 'center',
                            }}
                        >
                            <View style={{
                                marginLeft: 2.5,
                                justifyContent: 'center',
                            }}>
                                <Image source={data.productImageList[0].downloadURL}
                                    style={{
                                        width: screenSize.height / 6.3,
                                        height: screenSize.height / 6.3,
                                        resizeMode: "cover",
                                        borderRadius: 20,
                                        aspectRatio: 1,
                                    }}>
                                </Image>
                            </View>
                            <View style={{
                                alignItems: "center",
                                justifyContent: 'center',
                                alignSelf: 'center',
                                flex: 1,
                            }}>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        fontSize: (screenSize.height / 60) + (screenSize.width / 165),
                                        color: COLOURS.black,
                                        fontWeight: "600",
                                        textAlign: 'center'
                                    }}>

                                    {data.ShortDescription}
                                </Text>
                                <View style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: 'center',
                                }}>
                                    <Text style={{
                                        fontSize: (screenSize.height / 50) + (screenSize.width / 155),
                                        textAlign: 'center',
                                        fontWeight: '900'
                                    }}> EGP </Text>
                                    <Text numberOfLines={3} style={{
                                        fontSize: (screenSize.height / 55) + (screenSize.width / 160),
                                        fontWeight: "bold",
                                        textAlign: 'center'
                                    }}>{data.Price}</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </ScrollView>
        </View>

    )
}

export default Favorites

const styles = StyleSheet.create({
    AdsWraper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
    },
})