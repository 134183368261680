import React, { useEffect, useState, setState, useCallback, useRef } from 'react';
import { StyleSheet, Animated, Platform, FlatList, Button, View, TextInput, LogBox, Text, ActivityIndicator, ScrollView, TouchableOpacity, Picker, Dimensions, Image } from 'react-native';
import firebase from '../firebase/firebaseConfig';
import { COLOURS } from '../components/database/Database';
import ScreenSize from "../components/ScreenSize";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import ChildImage from './ChildImage';
import openNotificationWithIcon from '../components/openNotificationWithIcon';
// import Alert from "react-native-awesome-alerts";

export default function EditProfile({ navigation, route }) {
    const screenSize = ScreenSize();
    const [name, setname] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [photoURL, setphotoURL] = useState('');
    const [routeImage, setrouteImage] = useState(null);
    const [loading, setloading] = useState(false);
    const [UserData, setUserData] = useState([]);
    // const [email, setemail] = useState('');

    const userID = route?.params?.userID
    const userDocRef = firebase.firestore()
        .collection('Users')
        .doc(userID)


    useEffect(() => {
        setloading(true)
        firebase.firestore()
            .collection('Users')
            .doc(userID)
            .get()
            .then(snapshot => {
                if (snapshot.exists) {
                    setUserData(snapshot.data())
                    setname(snapshot.data()?.displayName)
                    setphotoURL(snapshot.data()?.photoURL)
                    setphoneNumber(snapshot.data()?.phoneNumber)
                    // setemail(snapshot.data()?.email)
                }
            })
        setloading(false)
    }, [userID]);

    const addToFirebase = async () => {
        setloading(true)
        if (routeImage) {
            let downloadURL = await uploadToStorage(routeImage)
            savePostData(downloadURL);

        } else if (!routeImage) {
            await userDocRef
                .update({
                    displayName: name,
                    phoneNumber: phoneNumber,
                    photoURL: photoURL,
                    // email: email,
                })
                .then(navigation.navigate('MyAccount', { profileID: UserData?.uid }))
            // .then(alert('Edit Complete'))
            openNotificationWithIcon('success', 'Complete', 'Edit Complete')
            setloading(false)
        }
    }

    const uploadToStorage = async (uri) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        const childPath = `Users/${userID}/profilePic/${Math.random().toString(36)}`;
        const ref = firebase.storage().ref().child(childPath)
        const task = await ref.put(blob);
        const downloadURL = await task.ref.getDownloadURL();
        return downloadURL;
    }

    const savePostData = async (downloadURL) => {
        await userDocRef
            .update({
                displayName: name,
                phoneNumber: phoneNumber,
                photoURL: downloadURL,
                // email: email,
            })
            .then(navigation.navigate('MyAccount', { profileID: UserData?.uid }))
        openNotificationWithIcon('success', 'Complete', 'Edit Complete')
        // .then(alert('Edit Complete'))
        setloading(false)
    }

    const imageCallback = (callback) => {
        setrouteImage(callback[0].uri)
    }


    if (UserData) {
        // <h1> {notesItem && notesItem.title} Notes</h1>  // use it when it is available from axios call
        return (
            <View style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
            }}>
                <ScrollView style={{ height: '100%', width: '100%' }} showsVerticalScrollIndicator={false}>
                    <View style={{ alignSelf: "center", justifyContent: 'center', marginVertical: 40 }}>
                        <View style={styles.profileImage}>
                            <Image source={{ uri: routeImage ? routeImage : photoURL }}
                                style={styles.imagepr}
                                resizeMode="cover" >
                            </Image>
                        </View>
                        <TouchableOpacity style={styles.dm} onPress={() => ChildImage(imageCallback)}>
                            <MaterialCommunityIcons name="camera-plus" size={24} color="black" />
                        </TouchableOpacity>
                    </View >

                    <View
                        style={{
                            paddingHorizontal: screenSize.isWide ? screenSize.width / 11 : 50,
                            justifyContent: 'center'
                        }}>
                        {/* <TextInput
                            style={styles.textInput}
                            onChangeText={(email) => setemail(email)}
                            placeholder='Email'
                            defaultValue={UserData?.email}
                        /> */}
                        <TextInput
                            style={styles.textInput}
                            onChangeText={(name) => setname(name)}
                            placeholder="Name"
                            defaultValue={UserData?.displayName}
                        />

                        <View style={{
                            flexDirection: 'row', borderBottomColor: '#ccc',
                            borderBottomWidth: 1,
                            marginBottom: 30,
                            paddingVertical: 4,
                            paddingHorizontal: 10,
                            alignItems: 'center'
                        }}>
                            <Image style={{ height: 10, width: 14 }} source={{ uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSowxTH-tRTPOTl5LvQIJEpSZ-73369EwehJfMzNc0Isw&s' }} />
                            <Text >
                                +2
                            </Text>

                            <TextInput
                                style={{
                                    paddingVertical: 4,
                                    paddingHorizontal: 10
                                }}
                                // style={styles.textInput}
                                onChangeText={(phoneNumber) => setphoneNumber(phoneNumber)}
                                placeholder="Phone Number"
                                defaultValue={UserData?.phoneNumber}
                            />
                        </View>

                        <View style={{ margin: 15, marginTop: 18, justifyContent: 'center', alignItems: 'center' }}>
                            {/* <TouchableOpacity
                                    style={{ height: 20, width: 140, borderRadius: 5, backgroundColor: '#888', justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}
                                    onPress={() => {
                                        navigation.navigate("ChildImage", { goingBackScreen: 'EditProfile' })
                                    }}>
                                    <Text style={{ fontSize: 14, fontWeight: '200' }}>
                                        Change Image
                                    </Text>
                                </TouchableOpacity> */}

                            {loading ?
                                <View>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                                        Loading...
                                    </Text>
                                    <ActivityIndicator size={"large"} />
                                </View>
                                :
                                <TouchableOpacity style={{ height: 40, width: 250, borderRadius: 10, backgroundColor: '#356', justifyContent: 'center', alignItems: 'center' }}
                                    onPress={() => addToFirebase()}>
                                    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        Edit Your Profile
                                    </Text>
                                </TouchableOpacity>}
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
    else {
        return <ActivityIndicator size={"large"}></ActivityIndicator>;
    }
}

const styles = StyleSheet.create({

    textInput: {
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        marginBottom: 30,
        paddingVertical: 4,
        paddingHorizontal: 10
    },
    textInputDelete: {
        borderBottomColor: '#ccc',
        borderBottomWidth: 5,
        marginBottom: 30,
        paddingVertical: 20,
        paddingHorizontal: 2,
        borderTopColor: '#0000ff',
        // borderRadius:50,
        borderEndWidth: 20

    },
    locationPicker: {
        marginBottom: 15
    },
    mapPreview: {
        marginBottom: 10,
        width: '100%',
        height: 150,
        borderColor: '#ccc',
        borderWidth: 1
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
    },
    imagePicker: {
        alignItems: 'center',
        marginBottom: 15
    },
    imagePreview: {
        width: '100%',
        height: 200,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#ccc',
        borderWidth: 1
    },
    image: {
        width: '100%',
        height: '100%'
    },
    imagepr: {
        flex: 1,
        height: undefined,
        width: undefined
    },
    profileImage: {
        width: 180,
        height: 180,
        borderRadius: 100,
        overflow: "hidden",
        marginTop: -10
    },
    headerButton: {
        marginHorizontal: 20
    },
    headerButtonText: {
        fontSize: 16,
        color: Platform.OS === 'android' ? 'white' : COLOURS.primary
    },
    ButtonBox: {
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        bottom: '50%',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignSelf: 'center',
        paddingHorizontal: 3,
        width: "100%",
        // padding: 2,
        // justifyContent: 'center',
        // right: 0,
    },
    phoneTextContainer: {
        flex: 1,
        backgroundColor: "white",
        height: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "black",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
    },
    phoneCountryCallingCodeText: {
        fontWeight: "bold",
        marginRight: 10,
    },
    dm: {
        position: 'absolute',
        backgroundColor: "#bdbdbd",
        position: "absolute",
        top: 20,
        width: 35,
        height: 35,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center"
    }
});