import React, { useState, useEffect } from "react";
import { GiftedChat } from "react-native-gifted-chat";
import { StyleSheet, View, Text, ImageBackground, Image, TouchableOpacity } from "react-native";
import firebase from "../firebase/firebaseConfig";
import {
  doc,
  updateDoc,
  serverTimestamp
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ScreenSize from "../components/ScreenSize";
import { Feather, AntDesign, MaterialCommunityIcons, Entypo, FontAwesome, Ionicons } from '@expo/vector-icons';

export default function ChatScreen(props) {
  const [userData, setuserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userB, setuserB] = useState([]);
  const productData = props.route.params?.productData;
  const combinedId = userData?.uid > props.route.params?.productData ? userData?.uid + props.route.params?.productData : props.route.params?.productData + userData?.uid
  // const combinedId = userData?.uid > productData ? userData?.uid + productData : productData + userData?.uid
  const screenSize = ScreenSize();

  useEffect(() => {
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        setuserData(data)
        // try {
        // const fetchData = async () => {

        firebase.firestore()
          .collection("Users")
          .doc(productData)
          .get()
          .then((snapshot) => {
            setuserB(snapshot.data());
            // console.log(snapshot.data());
          });

        firebase
          .firestore()
          .collection("chats")
          .doc("combinedId")
          .collection(data?.uid > props.route.params?.productData ? data?.uid + props.route.params?.productData : props.route.params?.productData + data?.uid)
          .orderBy("createdAt", "desc")
          .onSnapshot((querySnapshot) => {
            // console.log(querySnapshot.docs.map((doc) => doc.data()))
            setMessages(querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                // createdAt: doc.data().createdAt.toLocaleString()
                createdAt: doc.data().createdAt.toDate()
              }
            }));
          })

        // }
        // fetchData()

      } else {
        setuserData(null)
      }
    })
  }, [props.route.params?.productData]);




  async function handleSend(messages) {
    // console.log(messages);
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );

    messages.map((m) =>
      firebase
        .firestore()
        .collection("chats")
        .doc("combinedId")
        .collection(combinedId)
        .add(m)
    );

    // messages.map((m) => firebase.firestore()
    //     .collection('userChats')
    //     .doc(firebase.auth()?.currentUser?.uid).update([combinedId + ".lastMessage" ]: messages)
    //     )

    await updateDoc(doc(firebase.firestore(), "userChats", userData?.uid),
      {
        [combinedId + ".lastMessage"]: messages[0].text,

        [combinedId + ".date"]: serverTimestamp(),
      }
    );

    // .adOwner?.uid ? productData.adOwner?.uid : productData.uid
    await updateDoc(doc(firebase.firestore(), "userChats", productData),
      {
        [combinedId + ".lastMessage"]: messages[0].text,

        [combinedId + ".date"]: serverTimestamp(),
      }
    );
  }




  if (userData && messages) {
    // console.log(userB?.lastSignInTime)
    // console.log(userB?.lastSignInTime.toDate().toDateString())
    // const date = new Date(userB?.lastSignInTime && userB?.lastSignInTime.toDate().toLocaleTimeString());
    const date = new Date(userB?.lastSignInTime && userB?.lastSignInTime.toDate().toDateString());
    const displayDate =
      date.getDate()
      + "/" + (date.getMonth() + 1)
      + "/" + (date.getFullYear())
    // + "/" + (date.getHours())
    // + "/" + (date.getMinutes())

    return (
      <View style={{ flex: 1 }}>

        <ImageBackground
          style={{ flex: 1 }}
          source={{
            uri: "https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png",
          }}
        >
          <View style={{ flex: 1, paddingHorizontal: screenSize.isWide ? screenSize.width / 11 : 0 }}>

            <View style={{
              width: '100%', height: screenSize.height / 19, backgroundColor: '#133337', justifyContent: "center",
            }}>
              <View style={{ alignItems: 'center', marginLeft: 6, flexDirection: 'row', paddingVertical: 2 }}>
                <TouchableOpacity style={{ marginRight: 6 }} onPress={() => props.navigation.navigate('ChatsScreen')}>
                  <MaterialCommunityIcons name="arrow-left" size={screenSize.height / 30} color='#fff' />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {
                  props.navigation.navigate('MyAccount', {
                    // userB: userB,
                    profileID: userB.uid
                  })
                }} style={{ alignItems: 'center', flexDirection: 'row' }}>
                  <Image source={{ uri: userB?.photoURL }} style={{ height: screenSize.height / 23, width: screenSize.height / 23, borderRadius: 300, marginRight: 10 }} />
                  <View>
                    <Text style={{ fontSize: 16, fontWeight: '600', color: "#fff" }}>
                      {userB?.displayName}
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: '400', color: "#fff" }}>
                      {/* Last Seen: {userB?.lastSignInTime.toDateString()} */}
                      Last Seen: {displayDate} At: {userB?.lastSignInTime && userB?.lastSignInTime.toDate().toLocaleTimeString()}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <GiftedChat
              messages={messages}
              onSend={handleSend}
              renderAvatar={null}
              user={{
                _id: userData?.uid,
                name: userData?.displayName,
                avatar: userData?.photoURL,
              }}
              textInputProps={{ autoFocus: true, blurOnSubmit: false }}
              isKeyboardInternallyHandled={false}
              keyboardShouldPersistTaps="always"

            // renderAvatarOnTop={true}
            // alwaysShowSend
            // showUserAvatar={true}
            // renderUserName
            // renderUsernameOnMessage
            // showAvatarForEveryMessage
            // renderAvatarOnTop={true}
            // scrollToBottom
            // key={messages.id}
            />
          </View>
        </ImageBackground>
      </View>
    )
  } else if (!userData) {
    return <Text>You Are Not Logged In</Text>;
  } else {
    return <Text>Start Typing !</Text>
  }

  // if (userData && messages) {
  //   return (
  //     <View style={styles.container}>
  //       <Text>
  //         You have to log in
  //       </Text>
  //     </View>
  //   )
  // }

  // else if (!isUser) {
  //   return (
  //     <View style={styles.container}>
  //       <Text>
  //         You have to log in
  //       </Text>
  //     </View>
  //   )
  // }

  // else {
  //   return (
  //     <View style={styles.container}>
  //       <Text>
  //         Start Typing !
  //       </Text>
  //     </View>
  //   )
  // }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  input: {
    height: 50,
    width: "100%",
    borderWidth: 1,
    padding: 15,
    marginBottom: 20,
    borderColor: "gray",
  },
});
