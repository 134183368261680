import { View, Text, FlatList, TouchableOpacity, ScrollView, StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import firebase from "../firebase/firebaseConfig";
import { COLOURS } from '../components/database/Database';
import { Image } from 'react-native';
import ScreenSize from '../components/ScreenSize';
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const Following = (props) => {
    const [product, setproduct] = useState([]);
    const screenSize = ScreenSize();
    const styles = useStyles();
    useEffect(() => {
        onAuthStateChanged(getAuth(), (data) => {
            if (data) {
                firebase
                    .firestore()
                    .collection("following")
                    .doc(getAuth()?.currentUser?.uid)
                    .collection('userFollowing')
                    .orderBy("followTime", "desc")
                    .onSnapshot((snapshot) => {
                        setproduct(snapshot.docs.map((doc) => doc.data()));
                        console.log(snapshot.docs.map((doc) => doc.data()))
                    });
            }
            setproduct([])
        })
    }, [])

    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: ScreenSize().isWide ? '50%' : '100%', alignSelf: 'center' }}>
            <ScrollView style={{ width: '100%', height: '100%' }}>
                {product.length === 0 ? <Text> You Are Not Following Any One</Text> : null}
                {product.map((data, index) => {
                    return (
                        // <TouchableOpacity
                        //     key={index}
                        //     onPress={() => {
                        //         props.navigation.navigate("MyAccount", {
                        //             profileID: data.uid,
                        //         });
                        //     }}
                        //     style={{
                        //         alignItems: "center",
                        //         flexDirection: "row",
                        //         marginTop: 25,
                        //         borderWidth: 1,
                        //         borderColor: "#666",
                        //         borderRadius: 50,
                        //     }}
                        // >
                        //     <Image
                        //         source={{ uri: data.photoURL }}
                        //         style={{
                        //             height: screenSize.height / 14,
                        //             width: screenSize.height / 14,
                        //             borderRadius: 300,
                        //             margin: 6,
                        //         }}
                        //     />
                        //     <View style={{ marginHorizontal: 10 }}>
                        //         <Text style={{ fontSize: 20, fontWeight: "700", color: "#444" }}>
                        //             {data.displayName}
                        //         </Text>
                        //     </View>
                        // </TouchableOpacity>

                        <TouchableOpacity
                            onPress={() => props.navigation.navigate("MyAccount", {
                                profileID: data.uid,
                            })}
                            key={index}
                            style={{
                                width: '98%',
                                height: screenSize.height / 6,
                                marginVertical: 5,
                                marginHorizontal: 5,
                                flexDirection: 'row',
                                backgroundColor: '#d0d0d0',
                                borderRadius: 20,
                                alignSelf: 'center'
                            }}
                        >
                            <View style={{
                                marginLeft: 2.5,
                                justifyContent: 'center',
                            }}>
                                <Image source={data.photoURL}
                                    style={{
                                        width: screenSize.height / 6.3,
                                        height: screenSize.height / 6.3,
                                        resizeMode: "cover",
                                        borderRadius: 20,
                                        aspectRatio: 1,
                                    }}>
                                </Image>
                            </View>
                            <View style={{
                                alignItems: "center",
                                justifyContent: 'center',
                                alignSelf: 'center',
                                flex: 1,
                            }}>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        fontSize: (screenSize.height / 60) + (screenSize.width / 165),
                                        color: COLOURS.black,
                                        fontWeight: "600",
                                        textAlign: 'center'
                                    }}>
                                    {data.displayName}
                                </Text>
                                <Text style={{
                                    fontSize: (screenSize.height / 60) + (screenSize.width / 165),
                                    color: COLOURS.black,
                                    fontWeight: "40",
                                }}>
                                    {data.email}
                                </Text>
                                <Text numberOfLines={3} style={{
                                    fontSize: (screenSize.height / 60) + (screenSize.width / 165),
                                    color: COLOURS.black,
                                    fontWeight: "40",
                                }}>
                                    {data.phoneNumber}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </ScrollView>
        </View>

    )
}

export default Following
function useStyles() {
    return StyleSheet.create({

    })
}