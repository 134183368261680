import React, { useState, useEffect } from 'react';
// import * as WebBrowser from 'expo-web-browser';
// import * as Google from 'expo-auth-session/providers/google';
// import * as Facebook from 'expo-auth-session/providers/facebook';
import { getAuth, signInWithCredential, FacebookAuthProvider, onAuthStateChanged, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { Button, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import firebase from '../firebase/firebaseConfig'
import {
    collection,
    query,
    where,
    getDocs,
    setDoc,
    doc,
    updateDoc,
    serverTimestamp,
    getDoc,
    Timestamp,
    arrayUnion,
    onSnapshot
} from "firebase/firestore";

// WebBrowser.maybeCompleteAuthSession();

export default function SigninWithFacebook({ navigation }) {
    const [isUser, setisUser] = useState(null)
    const [loading, setloading] = useState(false)
    // const currentUser = firebase.auth().currentUser

    const signInWithFacebook = async () => {
        const provider = new FacebookAuthProvider()
        // .setCustomParameters({ 'display': 'popup' })
        setloading(true)
        signInWithRedirect(getAuth(), provider)
            .then((re) => {
                // setisUser(re)
                console.log(re)
                setUserData(re)
            })
            .catch((err) => {
                console.log(err)
            })
        // setUserData()
        // setloading(false)
    }

    const setUserData = async (re) => {
        if (re?.user?.metadata?.creationTime === re?.user?.metadata.lastSignInTime) {
            await firebase
                .firestore()
                .collection('Users')
                .doc(re?.user?.uid)
                .set({
                    displayName: re?.user?.providerData[0]?.displayName,
                    email: re?.user?.providerData[0]?.email,
                    uid: re?.user?.uid,
                    photoURL: re?.user?.providerData[0]?.photoURL,
                    phoneNumber: re?.user?.providerData[0]?.phoneNumber,
                    provider: 'facebook',

                    creationTime: re?.user?.metadata.creationTime,
                    lastSignInTime: re?.user?.metadata.lastSignInTime,
                })
            setDoc(doc(firebase.firestore(), "userChats", re?.user?.uid), {})
        }

        if (re?.user?.metadata?.creationTime != re?.user?.metadata?.lastSignInTime) {
            await firebase
                .firestore()
                .collection('Users')
                .doc(re?.user?.uid)
                .update({
                    // name: re?.user?.providerData[0]?.displayName,
                    // email: re?.user?.providerData[0]?.email,
                    // uid: re?.user?.uid,
                    // photo: re?.user?.providerData[0]?.photoURL,
                    // phoneNumber: re?.user?.providerData[0]?.phoneNumber,
                    // creationTime: re?.user?.metadata.creationTime,
                    lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
                    // lastSignInTime: re?.user?.metadata.lastSignInTime,
                })
            firebase
                .firestore()
                .collection('activityLog')
                .doc(getAuth()?.currentUser?.uid)
                .collection('activityLog')
                .add({
                    signIn: firebase.firestore.FieldValue.serverTimestamp(),
                    // lastSignInTime: googleUserData.user.metadata.lastSignInTime,
                })
        }
        setloading(false)
    }

    // useEffect(() => {
    // onAuthStateChanged(getAuth(), (data) => {
    // if (data) {
    // console.log(data)
    // setloading(true)
    // if (firebase.auth().currentUser.metadata.creationTime === firebase.auth().currentUser.metadata.lastSignInTime) {

    //     firebase
    //         .firestore()
    //         .collection('Users')
    //         .doc(firebase.auth().currentUser.uid)
    //         .set({
    //             name: currentUser.displayName,
    //             email: currentUser.providerData[0].email,
    //             uid: currentUser.uid,
    //             photo: currentUser.photoURL,
    //             phoneNumber: currentUser.phoneNumber,
    //             creationTime: currentUser.metadata.creationTime,
    //             lastSignInTime: currentUser.metadata.lastSignInTime,
    //             // data:JSON.stringify(data)
    //             // metadata: getAuth().currentUser.metadata[0],
    //             // providerId: data.providerId,
    //             // providerData: data.providerData,
    //             // emailVerified: data.emailVerified,
    //             // rooms: {}
    //         })
    //     setDoc(doc(firebase.firestore(), "userChats", currentUser.uid), {})
    // }

    // if (firebase.auth().currentUser.metadata.creationTime != firebase.auth().currentUser.metadata.lastSignInTime) {
    //     firebase
    //         .firestore()
    //         .collection('Users')
    //         .doc(getAuth().currentUser.uid)
    //         .update({
    //             name: currentUser.displayName,
    //             email: currentUser.providerData[0].email,
    //             uid: currentUser.uid,
    //             photo: currentUser.photoURL,
    //             phoneNumber: currentUser.phoneNumber,
    //             creationTime: currentUser.metadata.creationTime,
    //             lastSignInTime: currentUser.metadata.lastSignInTime,
    //         })
    // }
    // } else {
    //     setisUser(null)
    // }
    // })
    //     setloading(false)
    //     console.log(isUser)
    // }, [])

    return (
        <TouchableOpacity
            // disabled={!request}
            onPress={signInWithFacebook}>
            {loading ? <ActivityIndicator size='small'></ActivityIndicator> : null}
            <Image style={{ height: 42, width: 42 }} source={{ uri: 'https://www.edigitalagency.com.au/wp-content/uploads/Facebook-logo-blue-circle-large-transparent-png.png' }}></Image>
        </TouchableOpacity>
    );
}