import React, { useEffect, useState, useRef, useCallback } from 'react';
import { StyleSheet, Platform, View, TextInput, Text, ActivityIndicator, ScrollView, TouchableOpacity, Picker, Image, Pressable } from 'react-native';
import firebase from '../firebase/firebaseConfig';
import { COLOURS } from '../components/database/Database';
import ScreenSize from "../components/ScreenSize";
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { Carousel } from 'antd';
import ChildImage from './ChildImage';
import * as Animatable from 'react-native-animatable'
import { Spin } from 'antd';
import MapPreview from '../components/MapPreview';
import LocationPicker from '../components/LocationPicker';
import CityDropDown from './CityDropDown';
import openNotificationWithIcon from '../components/openNotificationWithIcon';

// import Alert from "react-native-awesome-alerts";
// import { getStorage, ref, deleteObject } from "firebase/storage";

export default function EditScreen({ navigation, route }) {
  const [photos, setphotos] = useState([]);
  const [images, setImages] = useState([])
  const styles = useStyles();
  const screenSize = ScreenSize();
  const [ShortDescription, setShortDescription] = useState('');
  const [LongDescription, setLongDescription] = useState('');
  const [Price, setPrice] = useState('');
  const [picker, setPicker] = useState('gamingLaptop');
  const [conditionPicker, setconditionPicker] = useState("new");
  const [product, setproduct] = useState([])
  const [pictures, setPictures] = useState(images.length === 0 ? photos : images)
  const [deletedItems, setDeletedItems] = useState([])
  const [loadingOnlineData, setLoadingOnlineData] = useState(false)
  const [loadingLocalImages, setloadingLocalImages] = useState(false)
  const [loadingEditingData, setloadingEditingData] = useState(false)

  const [Location, setLocation] = useState(null);
  const [address, setaddress] = useState(null);
  const [showLocationPicker, setShowLocationPicker] = useState(true)
  const [showCityDropDown, setShowCityDropDown] = useState(true)

  const [disableData, setDisableData] = useState({})
  const ref = useRef(null);
  const userDocRef = firebase.firestore().collection('Users').doc(firebase.auth().currentUser?.uid).collection('Ads').doc(route.params?.productId)
  // const [modalVisible, setModalVisible] = useState(false)
  // const [awesomeAlert, setAwesomeAlert] = useState(false);
  // const [animation, setAnimation] = useState('slideOutUp');


  useEffect(() => {
    setLoadingOnlineData(true)
    firebase.firestore()
      .collection('Ads')
      .doc(route?.params?.productId)
      .get()
      .then(snapshot => {
        setphotos(snapshot.data()?.productImageList)
        setPictures(snapshot.data()?.productImageList)
        setproduct(snapshot.data())
        setPicker(snapshot.data()?.ProductType)
        setconditionPicker(snapshot.data()?.condition)
        setLongDescription(snapshot.data()?.LongDescription)
        setShortDescription(snapshot.data()?.ShortDescription)
        setPrice(snapshot.data()?.Price)
        setDisableData(snapshot.data())
        setLoadingOnlineData(false)

        if (snapshot.data().Location != undefined) {
          setaddress(snapshot.data().Address)
          setLocation(snapshot.data().Location)
          setShowCityDropDown(false)
          setShowLocationPicker(false)
        }
        // console.log(snapshot.data())
      })
  }, [route.params?.productId]);

  async function addToFirebase() {
    if (pictures.length == 0) {
      openNotificationWithIcon('error', 'No Image', "You can't Publish your ad without an image")
      // alert("You Must Add An Image");
    } else if (ShortDescription.length < 4) {
      openNotificationWithIcon('error', "Title can't be empty", "You have to add title to your ad")
      // alert("You Have To Add Title To Your Ad");
    } else if (!address) {
      openNotificationWithIcon('error', "Location can't be empty", "Choosing a location is a must")
      // alert("You Must Choose Ad Location");
    } else if (images.length == 0 && photos.length != 0) {
      // console.log('images.length == 0 && photos.length != 0')
      deleteItemsFromFirebaseStorage()
      await userDocRef
        .update({
          ShortDescription: ShortDescription,
          LongDescription: LongDescription,
          Price: Price,
          productImageList: pictures,
          ProductType: picker,
          Address: address,
          Location: Location,
        })
        .then(() => {
          return firebase.firestore()
            .collection('Ads')
            .doc(route.params?.productId)
            .update({
              ShortDescription: ShortDescription,
              LongDescription: LongDescription,
              Price: Price,
              productImageList: pictures,
              ProductType: picker,
              condition: conditionPicker,
              Address: address,
              Location: Location,
            })

        })
        .then(navigation.navigate('HomeScreen'))
      openNotificationWithIcon('success', 'Complete', 'Edit Complete')
      // alert('Edit Complete')
      // .then(deleteItemsFromFirebaseStorage())
    }

    else if (images.length != 0 && photos.length != 0) {
      deleteAllItemsFromFirebaseStorage()
      uploadToStorage(pictures)
      // console.log('images.length != 0 && photos.length != 0')
      // deleteItemsFromFirebaseStorage()
      // setDeletedItems([...deletedItems, id.downloadURL])
    }
    else if (images.length !== 0 && photos.length == 0) {
      uploadToStorage(pictures)
    }
    setloadingEditingData(false)
    // alert('Edit Complete')
  }

  const uploadToStorage = async (images, coverUri) => {
    const random = []
    for (let item of images) {
      const response = await fetch(item.uri)
      const blob = await response.blob();
      const childPath = `Ads/${firebase.auth().currentUser?.uid}/${userDocRef.id}/${Math.random().toString(36)}`;
      const ref = firebase.storage().ref().child(childPath)
      await ref
        .put(blob)
        .then(function () {
          return ref.getDownloadURL();
        })
        .then(function (url) {
          delete item.uri
          random.push({ ...item, downloadURL: url, fileSize: blob.size, storagePath: childPath });
        })
    }

    setPictures(random)
    await userDocRef
      .update({
        ShortDescription: ShortDescription,
        LongDescription: LongDescription,
        Price: Price,
        productImageList: random,
        ProductType: picker,
        condition: conditionPicker,
        Location: Location,
        Address: address,
      })
      .then(() => {
        return firebase.firestore()
          .collection('Ads')
          .doc(route.params?.productId).update({
            ShortDescription: ShortDescription,
            LongDescription: LongDescription,
            Price: Price,
            productImageList: random,
            ProductType: picker,
            condition: conditionPicker,
            Location: Location,
            Address: address,
          })
      })
      .then(() => { return firebase.firestore().collection('Users').doc(product.adOwner.uid).update({ lastAdLocation: { address: address, coordinates: Location } }) })
      .then(navigation.navigate('HomeScreen'))
    openNotificationWithIcon('success', 'Complete', 'Edit Complete')
    // alert('Edit Complete')
  }

  const deleteItemsFromFirebaseStorage = () => {
    for (let image of deletedItems) {
      firebase.storage().refFromURL(image).delete()
    }
  }

  const deleteAllItemsFromFirebaseStorage = () => {
    for (let image of photos) {
      // console.log('storage delete ref: ', `Ads/${firebase.auth().currentUser?.uid}/${route.params?.productId}`)
      firebase.storage().refFromURL(image.downloadURL).delete()
    }
  }

  const deleteItemById = id => {
    const filteredData = pictures.filter(item => item !== id).map((image, index) => {
      return { ...image, orderNumber: index + 1 }
    })
    setPictures(filteredData);
    setDeletedItems([...deletedItems, id.downloadURL])
    // console.log(id.downloadURL)
  }

  const changeImageOrder = item => {
    const arr = [...pictures];
    const fromIndex = arr.indexOf(item)
    const toIndex = 0;
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
    ref.current?.goTo(0, false)
    setPictures(arr)
  }

  const imageCallback = (callback) => {
    if (callback != 'canceled') {
      setImages(callback)
      setPictures(callback)
      setloadingLocalImages(false)
    } else {
      setloadingLocalImages(false)
      console.log('canceled')
    }
  }

  // const deleteEntireFirebaseAd = () => {
  //   firebase.firestore()
  //     .collection('Users')
  //     .doc(firebase.auth().currentUser.uid)
  //     .collection('Ads')
  //     .doc(route.params?.productId)
  //     .delete()
  //   firebase.firestore()
  //     .collection('Ads')
  //     .doc(route.params?.productId)
  //     .delete()
  //     .then(() => { window.confirm('Deleted') })
  //     .then(setAwesomeAlert(false))
  //     .then(navigation.navigate('HomeScreen'))
  //   deleteFolder(`Ads/${firebase.auth().currentUser?.uid}/${route.params?.productId}`)
  //   // firebase.storage().ref(`Ads/${firebase.auth().currentUser?.uid}/${route.params?.productId}`).listAll().then()
  //   // setDeletedItems()sfgfsdh
  //   // deleteItemsFromFirebaseStorage()
  // }

  // function deleteFolder(path) {
  //   const ref = firebase.storage().ref(path);
  //   ref.listAll()
  //     .then(dir => {
  //       dir.items.forEach(fileRef => deleteFile(ref.fullPath, fileRef.name));
  //       dir.prefixes.forEach(folderRef => deleteFolder(folderRef.fullPath))
  //     })
  //     .catch(error => console.log(error));
  // }

  // function deleteFile(pathToFile, fileName) {
  //   const ref = firebase.storage().ref(pathToFile);
  //   const childRef = ref.child(fileName);
  //   childRef.delete()
  //   // console.log(fileName, pathToFile)
  // }

  // const AnimatedPressable = Animatable.createAnimatableComponent(Pressable)

  const slidesToShow = pictures?.length >= 3 ? 3 : pictures?.length

  const carouselWidth =
    pictures?.length >= 3 ? screenSize.width / 6.3 :
      pictures?.length == 2 ? '80%' :
        pictures?.length == 1 ? '50%' :
          '100%'


  const editDisabled = () => {
    if (ShortDescription == disableData.ShortDescription
      && LongDescription == disableData.LongDescription
      && Price == disableData.Price
      && picker == disableData.ProductType
      && conditionPicker == disableData.condition
      && address == disableData.Address
      && JSON.stringify(Location) == JSON.stringify(disableData.Location)
      && JSON.stringify(pictures) == JSON.stringify(disableData.productImageList)
      // && Address == disableData.Address
      // && Location == disableData.Location
    ) { return true } else { return false }
  }


  const pickOnMapHandler = () => {
    window.open("https://maps.google.com?q=" + Location.lat + "," + Location.lng);
  };

  const locationPickedHandler = useCallback((location) => {
    setLocation(location);
    setShowCityDropDown(false)
    setShowLocationPicker(false)
    console.log(location)
  }, []);

  const addressPickedHandler = useCallback((address) => {
    setaddress(address);
    setShowCityDropDown(false)
    setShowLocationPicker(false)
    console.log(address)
  }, []);

  const dropdownCoordinatesPickedHandler = useCallback((dropdownCoordinates) => {
    setLocation(dropdownCoordinates);
    setShowLocationPicker(false)
    setShowCityDropDown(true)
    console.log(dropdownCoordinates)
  }, []);

  const dropdownAddressPickedHandler = useCallback((dropdownAddress) => {
    setaddress(dropdownAddress);
    setShowLocationPicker(false)
    setShowCityDropDown(true)
    console.log(dropdownAddress)
  }, []);


  if (product) {
    return (
      <View style={{
        flex: 1,
        paddingHorizontal: screenSize.isWide ? screenSize.width / 4 : 0,
      }}>

        {loadingEditingData ?
          <Spin style={{ width: '100%', height: '100%' }} size="large" spinning={loadingEditingData} fullscreen />
          : null}

        {/* <View style={{ flex: 1, width: screenSize.isWide ? screenSize.width / 2 : '100%', alignSelf: 'center' }}> */}
        <ScrollView style={{
          // overflow: 'visible'
        }}
          showsVerticalScrollIndicator={false}
        >

          {loadingOnlineData ? (
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Loading Online Data...
              </Text>
              <ActivityIndicator size={"large"} />
            </View>
          ) : null}

          {loadingLocalImages ?
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Loading Local Images...
              </Text>
              <ActivityIndicator size={"large"} />
            </View>
            : null}

          {pictures?.length == 0 && !loadingOnlineData ?
            <View style={{ justifyContent: 'center', alignItems: 'center', marginVertical: 8 }}>
              <TouchableOpacity
                style={{
                  justifyContent: 'center', alignSelf: 'center', alignItems: 'center',
                  // backgroundColor: '#eaeaea',
                  // borderRadius: 50,
                  // height: 90, width: 90,
                }}
                onPress={() => {
                  setloadingLocalImages(true)
                  ChildImage(imageCallback)
                }}>
                {/* <MaterialCommunityIcons name="camera-plus-outline" size={70} color="black" /> */}
                <Image style={{
                  height: screenSize.ratio(8.5), width: screenSize.ratio(8.5),
                  // borderRadius: 50
                }} source={require('../assets/uploadImage2.png')} />
              </TouchableOpacity>
              {/* <Text style={{ fontSize: 14, fontWeight: '800', marginTop: 4 }}>Add Images To Your Product</Text> */}
            </View>
            : null}


          {pictures?.length != 0 ?
            <View
              style={{
                alignSelf: screenSize.isWide ? 'center' : undefined,
                width: '100%',
                height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                justifyContent: screenSize.isWide ? 'center' : undefined,
                // width: screenSize.isWide ? Number(carouselWidth) : '100%',
                // backgroundColor: 'yellow'
              }}>

              <Carousel
                infinite={false}
                ref={ref}
                slidesToShow={screenSize.isWide ? slidesToShow : 1}
                style={{
                  height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                  alignSelf: 'center',
                  // width: '100%',
                  // height: '100%',
                  // justifyContent: 'space-around',
                  // alignItems: 'center',
                  // alignContent: 'space-around'
                  // backgroundColor: 'coral'
                  // backgroundColor: 'azure'
                }}
                arrows={true}
                dots={true}
                waitForAnimate={true}
                draggable={true}
                swipeToSlide
              // slidesPerRow={4}
              >

                {pictures?.map((item, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        // width: 500000000000,
                        // width: screenSize.isWide
                        //   ? screenSize.width / 3
                        //   : screenSize.width,
                        // padding: 2,
                        // maxHeight: screenSize.width,
                        // overflow: 'hidden',
                        // backgroundColor: 'red',
                      }}
                    >
                      <View>

                        <TouchableOpacity style={{
                          height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                          width: screenSize.isWide ? carouselWidth : '100%',
                          alignSelf: 'center',
                          // overflow: 'hidden',
                          // borderRadius: 3,
                          // alignItems: "center",
                          // justifyContent: "center",
                          // borderBottomRightRadius: 20,
                          // borderBottomLeftRadius: 20,
                          // aspectRatio: 1,
                          // backgroundColor: 'aqua',
                        }}
                          onPress={() => { changeImageOrder(item, index) }}>


                          <Image
                            source={item.downloadURL ? item.downloadURL : item.uri}
                            style={{
                              borderRadius: 3,
                              alignSelf: 'center',
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottomRightRadius: 20,
                              borderBottomLeftRadius: 20,
                              height: '100%',
                              width: '100%',
                              aspectRatio: 1,
                              resizeMode: 'cover',
                              // height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                              // width: screenSize.isWide ? carouselWidth : '100%',
                              // backgroundColor: 'blue'
                            }} />

                          {index === 0 ?
                            <View
                              style={{
                                position: 'absolute',
                                width: '30%',
                                height: '22%',
                                backgroundColor: 'green',
                                top: 3,
                                // left: screenSize.isWide ? '11%' : 3,
                                left: 3,
                                // left: screenSize.isWide ? 42 : 3,
                                borderTopLeftRadius: 17,
                                borderBottomRightRadius: 17,
                                opacity: 0.8,
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}>
                              <Text
                                style={{
                                  fontSize: '150%',
                                  color: '#fff',
                                  fontWeight: '900',
                                  // fontSize: screenSize.ratio(60),
                                  // letterSpacing: 1,
                                }}>
                                Cover
                              </Text>
                            </View>
                            : null}


                          <TouchableOpacity
                            style={{
                              position: 'absolute', top: 6,
                              // right: screenSize.isWide ? '12%' : 6,
                              right: 6,
                              // right: screenSize.isWide ? 48 : 6,
                              opacity: 0.8
                            }}
                            onPress={() => { deleteItemById(item) }}>
                            <MaterialCommunityIcons name="delete-circle-outline" size={24} color="red" />
                          </TouchableOpacity>
                        </TouchableOpacity>
                      </View>
                    </View>)
                })}
              </Carousel>
            </View>
            : null}



          <View
            style={{
              paddingHorizontal: 12,
              marginTop: 19,

              // marginHorizontal: screenSize.isWide ? screenSize.width / 4 : 50,
              // justifyContent: 'center',
              // marginTop: 30,
            }}
          >
            <TextInput
              style={styles.textInput}
              onChangeText={(ShortDescription) => setShortDescription(ShortDescription)}
              placeholder='Title'
              defaultValue={product?.ShortDescription}
            />
            <TextInput
              style={styles.textInput}
              onChangeText={(Price) => setPrice(Price)}
              placeholder="Price"
              defaultValue={product?.Price}
            />

            {/* ////////////////////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////////////////////// */}
            <View style={{ marginTop: 20, }}>
              {address ? <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#eaeaea",
                  borderRadius: 3,
                  marginBottom: 3,
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "600", textAlign: 'center', color: '#4f4f4f' }}>
                  {address}
                </Text>
              </View> : null}

              <View style={{
                flexDirection: 'row',
                justifyContent: showCityDropDown ? 'space-between' : 'space-around',
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
                width: '100%',
                // backgroundColor: 'green'
              }}>

                {Location ? (
                  <View style={{
                    borderRadius: 5,
                    overflow: 'hidden',
                    borderColor: "#ccc",
                    borderWidth: 1,
                    height: showCityDropDown ? '100%' : screenSize.ratio(10),
                    width: showCityDropDown ? '59.5%' : '100%',
                  }}>
                    <MapPreview
                      style={{ width: '100%', height: '100%' }}
                      location={Location}
                      onPress={pickOnMapHandler}
                    ></MapPreview>
                  </View>
                ) : null}

                {showLocationPicker || address == null ? (
                  // <View >
                  <View style={{ width: '40%', }}>
                    <LocationPicker
                      navigation={navigation}
                      onLocationPicked={locationPickedHandler}
                      onAddressPicked={addressPickedHandler}
                    />
                  </View>
                ) : null}

                {showLocationPicker && showCityDropDown || address == null ? (
                  <View>
                    {/* <View style={{ width: '10%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}> */}
                    <Text style={{ color: '#133337', fontSize: 18, fontWeight: '700' }}>OR</Text>
                  </View>
                ) : null}

                {showCityDropDown || address == null ? (
                  // <View>
                  <View style={{ width: '40%' }}>
                    <CityDropDown
                      onLocationPicked={dropdownCoordinatesPickedHandler}
                      onAddressPicked={dropdownAddressPickedHandler}
                    />
                  </View>
                ) : null}
              </View>


              {!showLocationPicker && !showCityDropDown && Location ? (
                // {!showLocationPicker && !showCityDropDown && Location ? (
                <TouchableOpacity
                  style={{ alignItems: "center", justifyContent: "center", position: 'absolute', bottom: -12, alignSelf: 'center' }}
                  onPress={() => {
                    setLocation(null);
                    setaddress(null);
                    setShowCityDropDown(true)
                    setShowLocationPicker(true)
                  }}
                >
                  <MaterialCommunityIcons
                    name="close-circle"
                    size={24}
                    color="#52575D"
                  />
                </TouchableOpacity>
              ) : null}

            </View>

            {/* <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#eaeaea",
                marginTop: 20,
                borderRadius: 3,
                marginBottom: 3
              }}
            >
              <Text style={{ fontSize: 14, fontWeight: "600", textAlign: 'center', color: '#4f4f4f' }}>
                {address}
              </Text>
            </View>

            <View style={{
              flexDirection: 'row',
              justifyContent: showCityDropDown ? 'space-between' : 'space-around',
              alignItems: 'center',
              alignContent: 'center',
              alignSelf: 'center',
              width: '100%',
              // backgroundColor: 'green'
            }}>

              {Location ? (
                <View style={{
                  borderRadius: 5,
                  overflow: 'hidden',
                  borderColor: "#ccc",
                  borderWidth: 1,
                  height: showCityDropDown ? '100%' : screenSize.ratio(10),
                  width: showCityDropDown ? '69.5%' : '100%',
                  // height: showCityDropDown ? screenSize.ratio(7.5) : screenSize.ratio(9),
                  // flex: 1,
                  // height: screenSize.height / 5.5,
                  // height: 120,
                  // aspectRatio: 8 / 1,
                  // backgroundColor: 'red'
                }}>
                  <MapPreview
                    style={{ width: '100%', height: '100%' }}
                    location={Location}
                    onPress={pickOnMapHandler}
                  ></MapPreview>
                </View>
              ) : null}

              {showLocationPicker || address == null ? (
                <View style={{ width: '30%', }}>
                  <LocationPicker
                    navigation={navigation}
                    onLocationPicked={locationPickedHandler}
                    onAddressPicked={addressPickedHandler}
                  // lastAdLocation={user?.lastAdLocation}
                  />
                </View>
              ) : null}

              {showLocationPicker && showCityDropDown || address == null ? (
                <View style={{ width: '30%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={{ color: '#133337', fontSize: 18, fontWeight: '700' }}>OR</Text>
                </View>
              ) : null}

              {showCityDropDown || address == null ? (
                <View style={{ width: '30%' }}>
                  <CityDropDown
                    onLocationPicked={dropdownCoordinatesPickedHandler}
                    onAddressPicked={dropdownAddressPickedHandler}
                  // lastAdLocation={user?.lastAdLocation}
                  />
                </View>
              ) : null}
            </View>


            {!showLocationPicker && !showCityDropDown && Location ? (
              // {!showLocationPicker && !showCityDropDown && Location ? (
              <TouchableOpacity
                style={{ alignItems: "center", justifyContent: "center", position: 'absolute', bottom: -12, alignSelf: 'center' }}
                onPress={() => {
                  setLocation(null);
                  setaddress(null);
                  setShowCityDropDown(true)
                  setShowLocationPicker(true)
                }}
              >
                <MaterialCommunityIcons
                  name="close-circle"
                  size={24}
                  color="#52575D"
                />
              </TouchableOpacity>
            ) : null} */}
            {/* ////////////////////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////////////////////// */}







            {/* <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#eaeaea",
                marginTop: 32,
                borderRadius: 3
              }}
            >
              <Text style={{ fontSize: 14, fontWeight: "600", textAlign: 'center', color: '#4f4f4f' }}>
                {address}
              </Text>
            </View>


            {Location ? (
              <View style={{
                borderRadius: 5,
                overflow: 'hidden',
                borderColor: "#ccc",
                borderWidth: 2,
                height: 120,
                width: '100%',
                marginTop: 3
                // aspectRatio: 8 / 1,
              }}>
                <MapPreview
                  style={{ width: '100%', height: '100%' }}
                  location={Location}
                  onPress={pickOnMapHandler}
                ></MapPreview>
              </View>
            ) :

              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
                width: '100%',
                // flex: 1,
                // marginTop: -6
                // marginVertical: 10,
                // height: '20%'
                // backgroundColor: 'red',
              }}>

                <View style={{ width: '30%', }}>
                  {showLocationPicker || address == null ? (
                    <LocationPicker
                      navigation={navigation}
                      onLocationPicked={locationPickedHandler}
                      onAddressPicked={addressPickedHandler}
                    // lastAdLocation={user?.lastAdLocation}
                    />
                  ) : null}
                </View>

                <View style={{ width: '30%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                  {showLocationPicker && showCityDropDown || address == null ? (
                    <Text style={{ color: '#133337', fontSize: 18, fontWeight: '700' }}>OR</Text>
                  ) : null}
                </View>

                <View style={{ width: '30%', }}>
                  {showCityDropDown || address == null ? (
                    <CityDropDown
                      onLocationPicked={dropdownCoordinatesPickedHandler}
                      onAddressPicked={dropdownAddressPickedHandler}
                    // lastAdLocation={user?.lastAdLocation}
                    />
                  ) : null}
                </View>
              </View>
            }

            {!showLocationPicker && !showCityDropDown && Location ? (
              <TouchableOpacity
                style={{ alignItems: "center", justifyContent: "center", marginBottom: 13 }}
                onPress={() => {
                  setLocation(null);
                  setaddress(null);
                  setShowCityDropDown(true)
                  setShowLocationPicker(true)
                }}
              >
                <MaterialCommunityIcons
                  name="close-circle"
                  size={24}
                  color="#52575D"
                />
              </TouchableOpacity>
            ) : null} */}



            <TextInput
              style={styles.textInputDescription}
              onChangeText={(LongDescription) => setLongDescription(LongDescription)}
              placeholder="Description"
              defaultValue={product?.LongDescription}
              multiline
            />



            <View style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: 30,
              // paddingVertical: 6,
            }}>
              <Picker
                selectedValue={picker}
                onValueChange={(picker) => setPicker(picker)}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  marginVertical: 4,
                  paddingVertical: 3,
                  flex: 1,
                  padding: 5,
                  borderRadius: 5
                }}
                mode="dropdown"
                itemStyle={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  // padding: 10,
                }}
              >
                {/* <Picker.Item label="اختار" value="اختار" /> */}
                <Picker.Item label="Gaming Laptop" value="gamingLaptop" />
                <Picker.Item label="Business Laptop" value="businessLaptop" />
                {/* <Picker.Item label="scarf" value="scarf" /> */}
                {/* <Picker.Item label="bonnet" value="bonnet" /> */}
                <Picker.Item label="Workstation" value="workstation" />
                <Picker.Item label="Other" value="other" />
                {/* <Picker.Item label="غير ذلك" value="غير ذلك" /> */}
              </Picker>

              <Picker
                selectedValue={conditionPicker}
                onValueChange={(conditionPicker) =>
                  setconditionPicker(conditionPicker)
                }
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  paddingVertical: 3,
                  marginVertical: 4,
                  flex: 1,
                  padding: 5,
                  borderRadius: 5
                }}
                mode="dropdown"
                itemStyle={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  // padding: 10,
                }}
              >
                <Picker.Item label="New" value="new" />
                <Picker.Item label="Used" value="used" />
              </Picker>
            </View>


            <View style={{ marginTop: 40, justifyContent: 'center', alignItems: 'center', }}>
              {/* <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', width: '100%' }}>
                {pictures.length != 0 ?
                  <TouchableOpacity style={{
                    height: 30,
                    width: '49.5%',
                    borderRadius: 5, backgroundColor: '#b0b0b0',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                    onPress={() => {
                      setloadingLocalImages(true)
                      ChildImage(imageCallback)
                    }}>
                    <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#fff' }}>
                      Change Images
                    </Text>
                  </TouchableOpacity> : null}

                <TouchableOpacity
                  onPress={() => { setAwesomeAlert(oldState => !oldState) }}
                  // onPress={showAlert}
                  style={{
                    height: 30,
                    width: '49.5%',
                    borderRadius: 5,
                    backgroundColor: '#DD6B55',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#fff' }}>Delete This Ad</Text>
                </TouchableOpacity>
              </View> */}

              {/* {loadingEditingData ?
                <View >
                  <Text style={{ fontSize: screenSize.ratio(47), fontWeight: "bold", textAlign: "center" }}>
                    Editing...
                  </Text>
                  <ActivityIndicator size={"large"} />
                </View>
                : */}
              <TouchableOpacity
                disabled={editDisabled() || loadingEditingData || loadingLocalImages || loadingOnlineData}
                style={{
                  height: 40,
                  // marginTop: 18,
                  marginBottom: 7,
                  width: '100%',
                  borderRadius: 5,
                  backgroundColor: '#356',
                  // backgroundColor:editDisabled? '#290082': '#356',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: editDisabled() || loadingEditingData || loadingLocalImages || loadingOnlineData ? 0.5 : 1
                }}
                onPress={() => {
                  setloadingEditingData(true)
                  addToFirebase()
                  // setModalVisible(true)
                  // setAnimation("slideOutUp")
                  // setAnimationPressable('fadeIn')
                }}>
                <Text style={{ fontSize: screenSize.ratio(47), fontWeight: '800', color: '#fff' }}>
                  Edit Your Ad
                </Text>
              </TouchableOpacity>
              {/* } */}
            </View>
          </View>

          {/* {modalVisible && (
            <>
              <AnimatedPressable
                animation='fadeIn'
                duraton="600"
                style={styles.backdrop}
              // onPress={() => {
              //   setAnimation('slideOutDown')
              //   setAnimationPressable('fadeOut')
              //   setTimeout(toggleSheet, 650)
              // }}
              />
              <Animatable.View style={styles.sheet} animation='jello' duraton="800" easing='ease-in-out-expo'>
                <View>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Editing In Progress...
                  </Text>
                  <ActivityIndicator size={"large"} />
                </View>
              </Animatable.View>
            </>
          )} */}
          {screenSize.isWide && pictures.length > (screenSize.width / 2) / (screenSize.width / 6) ?  // (screenSize.width / 6) is the width of one item
            // {screenSize.isWide && product.productImageList?.length > (screenSize.width / 2) / (screenSize.width / 6) ?
            // {product.productImageList?.length != 0 && screenSize.isWide ?
            <View style={styles.ButtonBox}>
              <TouchableOpacity
                onPress={() => { ref.current?.prev(); }} >
                <AntDesign
                  name="leftcircle"
                  size={screenSize.ratio(30)}
                  color='#9b9b9b'
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => { ref.current?.next(); }}>
                <AntDesign
                  name="rightcircle"
                  size={screenSize.ratio(30)}
                  color='#9b9b9b'
                />
              </TouchableOpacity>
            </View>
            : null}
        </ScrollView>

        {/* <TouchableOpacity onPress={()=>console.log(disableData)}>

        </TouchableOpacity> */}





        {/* <Alert
          show={awesomeAlert}
          showProgress={false}
          title='Sure ?'
          message="Do You Want To Delete This Ad ?"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No"
          confirmText="Yes"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setAwesomeAlert(false)
          }}
          onConfirmPressed={deleteEntireFirebaseAd}
        /> */}
      </View>
    )
  }
  else {
    return <ActivityIndicator size={"large"}></ActivityIndicator>;
  }
}


function useStyles() {
  return StyleSheet.create({

    textInput: {
      borderBottomColor: '#ccc',
      borderBottomWidth: 1,
      paddingVertical: 4,
      paddingHorizontal: 2,
      marginTop: 12,
    },
    textInputDescription: {
      borderColor: "#ccc",
      // marginBottom: 30,
      marginTop: 25,
      // marginTop: 27,
      // paddingVertical: 60,
      paddingHorizontal: 2,
      borderWidth: 1,
      height: 100,
      borderRadius: 5

      // borderColor: '#ccc',
      // // borderBottomColor: '#ccc',
      // borderWidth: 1,
      // // borderBottomWidth: 1,
      // marginTop: 35,
      // marginBottom: 5,
      // // paddingVertical: 60,
      // paddingHorizontal: 2,
      // height: 100,
      // borderRadius: 5
    },

    locationPicker: {
      marginBottom: 15
    },
    mapPreview: {
      marginBottom: 10,
      width: '100%',
      height: 150,
      borderColor: '#ccc',
      borderWidth: 1
    },
    actions: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100%'
    },
    imagePicker: {
      alignItems: 'center',
      marginBottom: 15
    },
    imagePreview: {
      width: '100%',
      height: 200,
      marginBottom: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: '#ccc',
      borderWidth: 1
    },
    image: {
      width: '100%',
      height: '100%'
    },
    headerButton: {
      marginHorizontal: 20
    },
    headerButtonText: {
      fontSize: 16,
      color: Platform.OS === 'android' ? 'white' : COLOURS.primary
    },
    ButtonBox: {
      position: "absolute",
      flexDirection: "row",
      alignItems: "center",
      top: ScreenSize().ratio(13),
      justifyContent: "space-between",
      alignContent: "center",
      alignSelf: "center",
      // paddingHorizontal: 3,
      width: "107%",
      // paddingHorizontal: 3,
      // padding: 2,
      // justifyContent: 'center',
      // right: 0,
    },
    sheet: {
      // backgroundColor: "rgba(0, 0, 0, 0.3)",
      backgroundColor: "white",
      padding: 16,
      height: '30%',
      // height: ScreenSize().height / 1.5,
      width: "70%",
      position: "absolute",
      // bottom: -ScreenSize().height / 6,
      borderRadius: 20,
      zIndex: 1,
      alignSelf: 'center',
      top: ScreenSize().height * 0.35
      // marginHorizontal: 1000,
      // bottom: -20 * 7,
      // borderTopRightRadius: 20,
      // borderTopLeftRadius: 20,
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    },
  })
}